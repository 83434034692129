import { API } from "aws-amplify";

export const getCertRequirements = (level) => {
  const myInit = {
    queryStringParameters: {
      level
    },
  };
  return API.get("certification", `/getRequirements`, myInit);
};
