export function StepItem({ number, desc }) {
  return (
    <>
      <div className="flex gap-10 pl-[6px]">
        <div className="flex h-[50px] w-[50px] shrink-0 items-center justify-center rounded-full bg-white ring-1 ring-gray-400 laptop:h-[70px] laptop:w-[70px]">
          <div className="text-lg font-bold leading-normal text-black laptop:text-[24px]">
            {number}
          </div>
        </div>

        <div className="flex flex-col text-gray-100 ">
          <div className="w-[145px] text-xl font-semibold leading-normal">
            Step {number}
          </div>
          <div className="w-[179px] text-lg tablet:w-full">{desc}</div>
        </div>
      </div>
    </>
  );
}
