import { getCertRequirements } from "api";

const getRequirementsForLevel = async (level) => {
  if (!level) {
    return [];
  }

  return (await getCertRequirements(level))?.data;
};

const getCertificationDurationInMonth = (level) => {
  if (!level) {
    return 0;
  }
  if (level > 3) {
    return 12; // changed to 12 from feedback, kept the method for future changes
  } else {
    return 12;
  }
};

export const CertificationService = {
  getRequirementsForLevel,
  getCertificationDurationInMonth,
};
