import { useEffect } from 'react';

import { LoadingScreen } from 'components/loading';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { openZEWidget } from 'services';

export function WrapperItems() {
  const location = useLocation();

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params?.ze === "1") {
      setTimeout(() => {
        openZEWidget();
      }, 1000);
    }
  }, [location.search]);

  return (
    <>
      <LoadingScreen />

      {/* <Cookie /> */}
    </>
  );
}
