import { useRef, useState } from "react";

import { CyberCertLogo } from "assets";
import { ButtonWithRef } from "components";
import { Link, useNavigate } from "react-router-dom";
import { openZEWidget, useScrollPosition } from "services";
import { ROUTES } from "variables";

import { NavbarMenu } from "./navbar-menu";

export function Navbar({ className = "", variant = "primary" }) {
  const [showNavbarMenu, setShowNavbarMenu] = useState(false);

  const hamburgerBtnRef = useRef(null);
  const navbarMenuRef = useRef(null);

  const toggleNavbarMenu = () => {
    setShowNavbarMenu(!showNavbarMenu);
  };

  const handleCloseNavbarMenu = () => {
    setShowNavbarMenu(false);
  };

  const scrollPosition = useScrollPosition();

  const navigate = useNavigate();

  const shouldShowLoginBtn = () => {
    const paths = window.location.pathname.split("/");
    const path = paths[paths.length - 1];
    return ![ROUTES.SELF.LOGIN, ROUTES.SELF.PARTNER_LOGIN].includes(path);
  };

  const shouldShowScrollEffect = () => {
    return ![
      "claim",
      "invite",
      "login",
      "partner-form",
      "partner-login",
      "partner-register",
      "reset",
      "test",
    ].some((route) => window?.location?.pathname?.split("/")?.includes(route));
  };

  return (
    <>
      <nav
        id="cybercert-navbar"
        className={`grid border-b border-gray-200 transition-all duration-500 ease-in-out 
                  ${
                    scrollPosition > 0 ||
                    !shouldShowScrollEffect() ||
                    showNavbarMenu
                      ? "border-b bg-white bg-opacity-60 backdrop-blur-md"
                      : "border-none bg-transparent"
                  }
                  ${showNavbarMenu ? "grid-rows-[75px_auto]" : "grid-rows-[75px_0px]"}`}
      >
        <div className="flex flex-row items-center justify-between text-gray-900 2xl:px-0 px-5 tablet:px-[4%] 2xl:max-w-[1300px] w-full mx-auto">
          <Link
            id="cybercert-navbar-logo"
            to={ROUTES.ROOT}
            className="rounded-full px-0.5 py-2 hover:shadow focus:ring-4 focus:ring-blue-300"
          >
            <CyberCertLogo />
          </Link>

          <div className="hidden lg:block">
            <div className="gap-15 flex flex-row items-center justify-end font-semibold">
              <ul className="flex flex-row justify-end gap-8">
                <li>
                  <button
                    className="flex items-center justify-between rounded-full px-3 py-2 text-sm font-semibold focus:ring-4 focus:ring-blue-300 enabled:hover:shadow desktop:text-base"
                    onClick={openZEWidget}
                  >
                    Contact
                  </button>
                </li>
                <li>
                  <button
                    className="flex items-center justify-between rounded-full px-3 py-2 text-sm font-semibold focus:ring-4 focus:ring-blue-300 enabled:hover:shadow desktop:text-base"
                    onClick={() => navigate("/resources")}
                  >
                    Resources
                  </button>
                </li>
              </ul>

              {shouldShowLoginBtn() && (
                <ButtonWithRef
                  className="h-[35px] px-10 text-sm desktop:text-base"
                  variant="primary"
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  Login
                </ButtonWithRef>
              )}
            </div>
          </div>

          <div className="flex items-center lg:hidden" ref={hamburgerBtnRef}>
            <NavbarHamburgerBtn
              btnRef={hamburgerBtnRef}
              toggle={toggleNavbarMenu}
              show={showNavbarMenu}
            />
          </div>
        </div>

        <div
          className={`mx-auto w-full overflow-hidden border-gray-200 transition-all duration-500 ease-in-out lg:hidden ${showNavbarMenu ? "border-y px-6 py-5 sm:px-20" : "border-0 p-0"}`}
          id="cybercert-navbar-mobile-menu"
          ref={navbarMenuRef}
          style={{ WebkitTapHighlightColor: "transparent" }}
        >
          <NavbarMenu
            close={handleCloseNavbarMenu}
            isOpen={showNavbarMenu}
            variant={variant}
          />
        </div>
      </nav>
    </>
  );
}

export function NavbarHamburgerBtn({ toggle, show }) {
  return (
    <>
      <button
        onClick={toggle}
        className="flex items-center justify-center self-stretch rounded-lg p-2 text-sm text-gray-500
                  hover:bg-gray-50 hover:shadow
                  focus:ring-4 focus:ring-blue-300
                dark:text-gray-400"
      >
        <div className="flex h-[21px] w-[18px]">
          {show ? (
            <div className="flex h-full w-full flex-row items-center justify-center self-stretch">
              <svg
                className="h-5 w-5 text-gray-900"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          ) : (
            <div className="flex h-full w-full flex-row items-center justify-center self-stretch">
              <svg
                className="h-5 w-5 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </div>
          )}
        </div>
      </button>
    </>
  );
}
