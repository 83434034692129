import { API } from "aws-amplify";

export const lookupCheckout = async ({ checkoutId }) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        op: "lookup_checkout",
        checkoutId,
      },
    };

    result = await API.get("organisation", "/lookupOp", myInit);
  } catch (e) {
    console.error(e);
  }

  console.log(result);
  return result;
};
