import { PopupModal } from 'components/modal';
import { Modal } from 'flowbite-react';

export function AuditInfoModal({ active, setActive }) {
  return (
    <>
      <PopupModal
        dismissible={true}
        show={active}
        size={"md-static"}
        onClose={() => setActive(false)}
      >
        <Modal.Header>Assurance Details</Modal.Header>
        <Modal.Body>
          <>
            In addition to an owner or director of the organisation providing a
            signed attestation, both Platinum and Diamond certifications require
            an external validation audit to be conducted by an approved Audit
            Organisation.
            <br />
            <br />
            The fee for the external validation audit is included in your
            subscription fee.
          </>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </PopupModal>
    </>
  );
}
