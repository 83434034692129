import { useCallback, useEffect, useState } from "react";

import { lookupCheckout, verifyPartnerOffer } from "api";
import { openProductCheckoutSessionForLevel } from "api/functions/stripe";
import { CertCircle, HeroWoman } from "assets";
import { decode as base64_decode } from "base-64";
import {
  AuditInfoModal,
  ButtonWithRef,
  Carousel,
  CertCard,
  CheckoutModal,
  EliteCertCard,
  FlowbiteSpinner,
  HomeRequirements,
  HomeSteps,
} from "components";
import { usePartnerOffer } from "contexts";
import { HomeLayoutWrapper, LayoutWrapper } from "layouts";
import { PartnerRegisterClaim, Register } from "pages/auth-register";
import queryString from "query-string";
import {
  HiArrowNarrowRight,
  HiChevronDown,
  HiChevronRight,
  HiChevronUp,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CertificationService,
  clearUrlParamsState,
  compareBaseIds,
  openZEWidget,
  sanitizeInputWithSpace,
  scrollToElementSmoothly,
} from "services";
import { LEVEL_ICONS, LEVEL_NAMES, ROUTES } from "variables";

export function HomePage() {
  const { search } = useLocation();
  const { setPartnerOffer } = usePartnerOffer();

  const [action, setAction] = useState(); // home / claim
  const [claimInfo, setClaimInfo] = useState();

  const navigate = useNavigate();

  const setup = useCallback(async () => {
    const params = queryString.parse(search);

    let homeAction = "home";

    // reset stripe purchase session if exists
    sessionStorage.removeItem("STRIPE_PURCHASE_SESSION");

    if (!!params?.c) {
      try {
        const cParams = JSON.parse(base64_decode(params?.c));

        if (!!cParams?.partnerOffer) {
          let offer = await verifyPartnerOffer({
            code: cParams.partnerOffer,
          });

          setPartnerOffer({
            ABN: offer?.ABN,
            name: offer?.partner?.name,
            discount: offer?.discount,
            id: offer?.id,
            logo: offer?.partner?.logo,
            favicon: offer?.partner?.favicon,
          });
        }

        if (cParams.isPartnerClaim === true) {
          setClaimInfo({
            isPartnerClaim: true,
            claimCode: cParams?.claimCode,
            ABN: cParams?.ABN,
            email: cParams?.email,
            firstName: cParams?.firstName,
            lastName: cParams?.lastName,
            invite: cParams?.invite,
            inviteError: cParams?.inviteUsed,
          });
          homeAction = "partner_claim";
        } else if (
          cParams?.claim === true &&
          !!cParams?.ABN &&
          cParams?.purchased === true &&
          !!cParams?.checkoutId
        ) {
          let checkout = await lookupCheckout({
            checkoutId: cParams?.checkoutId,
          });
          if (!!checkout?.id) {
            sessionStorage.setItem("STRIPE_PURCHASE_SESSION", checkout?.id);
          }
          setClaimInfo({
            ABN: cParams?.ABN,
            checkoutId: cParams?.checkoutId,
            purchased: true,
          });
          homeAction = "claim";
        } else if (
          cParams?.confirm === true &&
          !!cParams?.email &&
          !!cParams?.c
        ) {
          setClaimInfo({
            confirm: true,
            email: cParams?.email,
            resend: cParams?.resend,
            c: cParams?.c,
          });
          homeAction = "claim";
        } else if (cParams?.confirm === true && !!cParams?.email) {
          setClaimInfo({
            confirm: true,
            email: cParams?.email,
            resend: cParams?.resend,
          });
          homeAction = "claim";
        } else if (
          cParams?.claim === true &&
          !!cParams?.ABN &&
          !!cParams?.partnerABN &&
          !!cParams?.claimCode
        ) {
          setClaimInfo({
            ABN: cParams?.ABN,
            partnerABN: cParams?.partnerABN,
            claimCode: cParams?.claimCode,
            error: cParams?.error,
          });
          homeAction = "claim";
        } else if (!!cParams?.claimCode) {
          // Invalid claim code
          console.error("Invalid claim code");
          navigate(ROUTES[404]);
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    setAction(homeAction);

    clearUrlParamsState();
  }, [search, setPartnerOffer]);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <>
      {
        {
          home: (
            <HomeLayoutWrapper>
              <HomePageHome />
            </HomeLayoutWrapper>
          ),
          claim: (
            <LayoutWrapper>
              <Register claimInfo={claimInfo} />
            </LayoutWrapper>
          ),
          partner_claim: (
            <LayoutWrapper>
              <PartnerRegisterClaim claimInfo={claimInfo} />
            </LayoutWrapper>
          ),
        }[action]
      }
    </>
  );
}

function HomePageHome() {
  const { partnerOffer } = usePartnerOffer();

  const [certReqs, setCertReqs] = useState(null);
  const [certPrices, setCertPrices] = useState(null);
  const [certValidMonths, setCertValidMonths] = useState(null);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [checkoutABN, setCheckoutABN] = useState();
  const [checkoutLevel, setCheckoutLevel] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const [showAuditInfoModal, setShowAuditInfoModal] = useState(false);
  const [showCertConditions, setShowCertConditions] = useState(true);

  useEffect(() => {
    (async () => {
      setCertReqs(
        await Promise.all(
          Object.values(LEVEL_NAMES).map(async (level) => {
            const requirements =
              await CertificationService.getRequirementsForLevel(level + 1);
            return requirements.sort((a, b) =>
              compareBaseIds(a.baseId, b.baseId)
            );
          })
        )
      );

      setCertPrices(
        // await Promise.all(
        //   Object.values(CertLevels).map(async (level) => {
        //     return await getProductStaticPrice({
        //       item: `LEVEL_${level}`,
        //     });
        //   }),
        // ),
        [95, 195, 395, 3595, 5995]
      );

      setCertValidMonths(
        await Promise.all(
          Object.values(LEVEL_NAMES).map(async (level) => {
            return CertificationService.getCertificationDurationInMonth(
              level + 1
            );
          })
        )
      );
    })();
  }, []);

  const toggleCertConditions = () => {
    setShowCertConditions(!showCertConditions);
  };

  const openCheckout = async (level) => {
    setCheckoutABN("");
    setShowCheckoutModal(true);
    setCheckoutLevel(level);
  };

  const proceedCheckout = async () => {
    await openProductCheckoutSessionForLevel({
      ABN: sanitizeInputWithSpace(checkoutABN),
      level: checkoutLevel,
      partnerOffer: sessionStorage.getItem("PARTNER_OFFER_CODE"),
    });

    // clear session's partner offer
    sessionStorage.removeItem("PARTNER_OFFER_CODE");
    sessionStorage.removeItem("PARTNER_OFFER_DISCOUNT");
    sessionStorage.removeItem("PARTNER_OFFER_PARTNER_ABN");
    sessionStorage.removeItem("PARTNER_OFFER_PARTNER_NAME");

    setShowCheckoutModal(false);
  };

  const handleOpenAuditInfoModal = () => {
    setShowAuditInfoModal(true);
  };

  const handleChangeSlide = (value) => {
    setActiveSlide(value);
  };

  return (
    <>
      <section
        id="cybercert-hero"
        className="bg-figure-home mx-auto w-full px-[20px] pt-[100px]"
      >
        <div className="home-hero items-center gap-[40px] tablet:flex-row tablet:gap-[20px] laptop:gap-[60px]">
          <div className="flex flex-col gap-[40px] tablet:min-w-[395px] laptop:min-w-[490px]">
            <div className="flex flex-col text-center text-[40px] font-semibold tablet:text-left tablet:text-[50px] laptop:text-[60px]">
              <h1 className="h1 !font-semibold !leading-tight xl:text-[50px]">
                Cyber{" "}
                <span className="tablet:bg-gradient-to-r tablet:from-black tablet:to-cyan-300 tablet:bg-clip-text tablet:text-transparent">
                  security
                </span>
                <br />
                Simplified for you
              </h1>
            </div>

            <p className="p hidden text-lg !font-normal !tracking-normal text-cyan-blue-medium-dark tablet:block tablet:min-w-[400px] laptop:text-[20px]">
              Know and Show your organisation is cyber secure. <br />
              Step-by-step, low cost, best practice for SMBs.
            </p>

            <div className="hidden items-center gap-4 tablet:flex">
              {/* <Button
                className="h-[40px] w-[190px] text-sm desktop:text-base"
                onClick={() => scrollToSection("cybercert-steps")}
              >
                3 steps <HiArrowNarrowRight className="h-5 w-5" />
              </Button> */}
              <ButtonWithRef
                className="h-[40px] w-[210px] text-sm desktop:text-base"
                onClick={() => scrollToElementSmoothly("purchase", 500)}
              >
                Get started from{" "}
                {!!partnerOffer?.discount
                  ? "$" + ((100 - partnerOffer.discount) / 100) * 95
                  : "$95"}{" "}
                <HiArrowNarrowRight className="h-5 w-5" />
              </ButtonWithRef>
            </div>
          </div>

          <img
            src={HeroWoman}
            alt="hero-woman.webp"
            className="min-w-[250px] tablet:max-w-[610px] tablet:translate-y-[31px] tablet:overflow-hidden laptop:min-w-[610px] laptop:max-w-[800px] laptop:translate-y-[41px]"
          />
        </div>
      </section>

      <section
        id="cybercert-show"
        className="self-stretch bg-gray-200 px-10 pb-20 pt-10 tablet:hidden tablet:px-5 tablet:pt-[60px]"
      >
        <div className="mx-auto flex w-full flex-col gap-10 tablet:gap-[60px]">
          <div className="text-center text-lg text-cyan-blue-medium-dark tablet:text-2xl">
            Know and Show your organisation is cyber secure. Step-by-step, low
            cost, best practice for SMBs.
          </div>

          <div className="flex flex-col justify-center gap-4">
            {/* <Button className="h-[45px]">
              3 steps{" "}
              <HiArrowNarrowRight
                className="h-5 w-5"
                onClick={() => scrollToSection("cybercert-steps")}
              />
            </Button> */}
            <ButtonWithRef
              className="h-[40px] w-full text-sm desktop:text-base"
              onClick={() => scrollToElementSmoothly("purchase", 500)}
            >
              Get started from $95 <HiArrowNarrowRight className="h-5 w-5" />
            </ButtonWithRef>
          </div>
        </div>
      </section>

      <section
        id="cybercert-steps"
        className="home-content bg-dark-green text-white"
      >
        <div className="mx-auto flex flex-col gap-[40px] tablet:gap-[60px]">
          <div className="subtitle-home">
            <h3 className="h3 !text-[32px] !font-semibold text-white">
              Get Certified in 3 Steps
            </h3>{" "}
          </div>

          <div className="tablet:flex tablet:flex-row-reverse tablet:items-center tablet:justify-between tablet:gap-[60px] laptop:justify-center">
            <HomeSteps />

            <div className="mx-auto mt-[40px] min-w-[250px] max-w-[350px] overflow-hidden tablet:mt-0 tablet:min-w-[400px] laptop:min-w-[480px]">
              <img src={CertCircle} alt="cert-circle.webp" className="w-full" />
            </div>
          </div>
        </div>
      </section>

      <section id="purchase" className="home-content bg-pricing">
        <div className="flex w-full flex-col gap-20 tablet:mx-auto tablet:gap-[60px]">
          <h3 className="h3 text-center !text-[32px] !font-semibold text-gray-900">
            Choose the right certification level for your business
          </h3>

          {/*********************** Start of Mobile Cert Cards ***************************/}
          <div className="mx-auto w-full mobile:max-w-[560px] tablet:hidden">
            <div className="flex flex-col gap-5 tablet:flex-row">
              {certReqs && certPrices && certValidMonths ? (
                <>
                  <CertCard
                    title={"Bronze"}
                    badge={LEVEL_ICONS[1]}
                    subtitle={"SMB1001:2023 - Level 1"}
                    text={
                      "For organisations that are starting out on their cyber journey and need the basics in place."
                    }
                    numReqs={certReqs[LEVEL_NAMES.Bronze]?.length}
                    certValid={`${certValidMonths[LEVEL_NAMES.Bronze]} months`}
                    assuranceLvl={"Self-Attested"}
                    price={certPrices[LEVEL_NAMES.Bronze]}
                    onClick={() => openCheckout(1)}
                  />

                  <CertCard
                    title={"Silver"}
                    badge={LEVEL_ICONS[2]}
                    subtitle={"SMB1001:2023 - Level 2"}
                    text={
                      "For organisations that rely on email and business apps, and want advanced cyber security."
                    }
                    numReqs={certReqs[LEVEL_NAMES.Silver]?.length}
                    certValid={`${certValidMonths[LEVEL_NAMES.Silver]} months`}
                    assuranceLvl={"Self-Attested"}
                    price={certPrices[LEVEL_NAMES.Silver]}
                    onClick={() => openCheckout(2)}
                  />

                  <CertCard
                    title={"Gold"}
                    badge={LEVEL_ICONS[3]}
                    subtitle={"SMB1001:2023 - Level 3"}
                    text={
                      "For organisations that want professional level cyber security that demonstrates best practice."
                    }
                    numReqs={certReqs[LEVEL_NAMES.Gold]?.length}
                    certValid={`${certValidMonths[LEVEL_NAMES.Gold]} months`}
                    assuranceLvl={"Self-Attested"}
                    price={certPrices[LEVEL_NAMES.Gold]}
                    onClick={() => openCheckout(3)}
                  />

                  <EliteCertCard
                    title={"Platinum"}
                    badge={LEVEL_ICONS[4]}
                    subtitle={"SMB1001:2023 - Level 5"}
                    text={
                      "For high performing businesses that have mature cyber security built on best-practice cyber risk management."
                    }
                    numReqs={certReqs[LEVEL_NAMES.Platinum]?.length}
                    certValid={`${
                      certValidMonths[LEVEL_NAMES.Platinum]
                    } months`}
                    assuranceLvl={"Self-Attested + Audit"}
                    price={certPrices[LEVEL_NAMES.Platinum]}
                    onClick={openZEWidget}
                  />

                  <EliteCertCard
                    title={"Diamond"}
                    badge={LEVEL_ICONS[5]}
                    subtitle={"SMB1001:2023 - Level 4"}
                    text={
                      "Top level certification for businesses that have the highest level of cyber maturity across all 5 core cyber security pillars."
                    }
                    numReqs={certReqs[LEVEL_NAMES.Diamond]?.length}
                    certValid={`${certValidMonths[LEVEL_NAMES.Diamond]} months`}
                    assuranceLvl={"Self-Attested + Audit"}
                    price={certPrices[LEVEL_NAMES.Diamond]}
                    onClick={openZEWidget}
                  />
                </>
              ) : (
                <div className="mx-auto self-stretch">
                  <FlowbiteSpinner />
                </div>
              )}
            </div>
          </div>
          {/*********************** Finish of Mobile Cert Cards ***************************/}

          {/*********************** Start of Desktop Cert Cards ***************************/}
          <div className={`hidden tablet:flex`}>
            {certReqs && certPrices && certValidMonths ? (
              <>
                <Carousel
                  className={`mx-auto flex py-5 tablet:max-w-[976px] laptop:max-w-[1015px]`}
                  onChange={handleChangeSlide}
                >
                  <div className={`flex flex-row gap-5 px-5`}>
                    <CertCard
                      title={"Bronze"}
                      badge={LEVEL_ICONS[1]}
                      subtitle={"SMB1001:2023 - Level 1"}
                      text={
                        "For organisations that are starting out on their cyber journey and need the basics in place."
                      }
                      numReqs={certReqs[LEVEL_NAMES.Bronze]?.length}
                      certValid={`${
                        certValidMonths[LEVEL_NAMES.Bronze]
                      } months`}
                      assuranceLvl={"Self-Attested"}
                      price={certPrices[LEVEL_NAMES.Bronze]}
                      onClick={() => openCheckout(1)}
                      tabIndex={activeSlide === 0 ? 0 : -1}
                    />

                    <CertCard
                      title={"Silver"}
                      badge={LEVEL_ICONS[2]}
                      subtitle={"SMB1001:2023 - Level 2"}
                      text={
                        "For organisations that rely on email and business apps, and want advanced cyber security."
                      }
                      numReqs={certReqs[LEVEL_NAMES.Silver]?.length}
                      certValid={`${
                        certValidMonths[LEVEL_NAMES.Silver]
                      } months`}
                      assuranceLvl={"Self-Attested"}
                      price={certPrices[LEVEL_NAMES.Silver]}
                      onClick={() => openCheckout(2)}
                      tabIndex={activeSlide === 0 ? 0 : -1}
                    />

                    <CertCard
                      title={"Gold"}
                      badge={LEVEL_ICONS[3]}
                      subtitle={"SMB1001:2023 - Level 3"}
                      text={
                        "For organisations that want professional level cyber security that demonstrates best practice."
                      }
                      numReqs={certReqs[LEVEL_NAMES.Gold]?.length}
                      certValid={`${certValidMonths[LEVEL_NAMES.Gold]} months`}
                      assuranceLvl={"Self-Attested"}
                      price={certPrices[LEVEL_NAMES.Gold]}
                      onClick={() => openCheckout(3)}
                      tabIndex={activeSlide === 0 ? 0 : -1}
                    />
                  </div>

                  <div className="flex flex-row gap-5 px-5">
                    <EliteCertCard
                      title={"Platinum"}
                      badge={LEVEL_ICONS[4]}
                      subtitle={"SMB1001:2023 - Level 4"}
                      text={
                        "For high performing businesses that have mature cyber security built on best-practice cyber risk management."
                      }
                      numReqs={certReqs[LEVEL_NAMES.Platinum]?.length}
                      certValid={`${
                        certValidMonths[LEVEL_NAMES.Platinum]
                      } months`}
                      assuranceLvl={"Self-Attested + Externally Audited"}
                      price={certPrices[LEVEL_NAMES.Platinum]}
                      onClick={openZEWidget}
                      onAssuranceClick={handleOpenAuditInfoModal}
                      tabIndex={activeSlide === 1 ? 0 : -1}
                    />

                    <EliteCertCard
                      title={"Diamond"}
                      badge={LEVEL_ICONS[5]}
                      subtitle={"SMB1001:2023 - Level 5"}
                      text={
                        "Top level certification for businesses that have the highest level of cyber maturity across all 5 core cyber security pillars."
                      }
                      numReqs={certReqs[LEVEL_NAMES.Diamond]?.length}
                      certValid={`${
                        certValidMonths[LEVEL_NAMES.Diamond]
                      } months`}
                      assuranceLvl={"Self-Attested + Externally Audited"}
                      price={certPrices[LEVEL_NAMES.Diamond]}
                      onClick={openZEWidget}
                      onAssuranceClick={handleOpenAuditInfoModal}
                      tabIndex={activeSlide === 1 ? 0 : -1}
                    />
                  </div>
                </Carousel>
              </>
            ) : (
              <div className="mx-auto self-stretch">
                <FlowbiteSpinner />
              </div>
            )}
          </div>
          {/*********************** Finish of Desktop Cert Cards ***************************/}

          <p className="p text-center text-sm text-gray-500">
            All prices are in Australian dollars and exclude GST.
            <br />
            This service is for Australian businesses only and requires a valid
            ABN.
            <br />
            Platinum and Diamond certification subscriptions include external
            audit fees.
          </p>
        </div>
      </section>

      <section id="cybercert-cert-reqs" className="home-content bg-alice-blue">
        <div className="mx-[10px] flex flex-col justify-center gap-[40px] tablet:mx-auto tablet:max-w-[950px] tablet:flex-row laptop:max-w-[1050px]">
          <div className="flex flex-col gap-[40px] tablet:gap-[60px]">
            <div className="flex flex-col gap-[40px]">
              <h3 className="h3 text-center !text-[32px] !font-semibold !text-gray-900">
                Certification requirements
              </h3>

              <p className="p mx-auto text-center text-lg tracking-tight text-gray-600 tablet:max-w-[560px] desktop:text-xl">
                Check out the requirements below to see what certification level
                your business may immediately qualify for.
              </p>
            </div>

            <HomeRequirements
              reqs={certReqs}
              prices={certPrices}
              validMonths={certValidMonths}
            />

            <div className="flex flex-col gap-[20px]">
              <p
                className="p flex cursor-pointer items-center gap-2 py-[2px] text-base"
                onClick={toggleCertConditions}
              >
                Certification Conditions and Prerequisites
                {showCertConditions ? (
                  <HiChevronUp className="h-6 w-6" />
                ) : (
                  <HiChevronDown className="h-6 w-6" />
                )}
              </p>

              {showCertConditions && (
                <div className="flex flex-col gap-[6px]">
                  <CertCondition>
                    Level 1, 2 and 3 certifications require director (or
                    equivalent) attestation and are valid for 12 months.
                  </CertCondition>
                  <CertCondition>
                    Level 4 and 5 Certifications require director (or
                    equivalent) attestation and an external audit and are valid
                    for 12 months.
                  </CertCondition>
                  <CertCondition>
                    Full details of the certification requirements are included
                    in your workbook that comes with your subscription.
                  </CertCondition>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <AuditInfoModal
        active={showAuditInfoModal}
        setActive={setShowAuditInfoModal}
      />

      <CheckoutModal
        active={showCheckoutModal}
        setActive={setShowCheckoutModal}
        ABN={checkoutABN}
        setABN={setCheckoutABN}
        continueFn={proceedCheckout}
        checkoutLevel={checkoutLevel}
      />
    </>
  );
}

function CertCondition({ children }) {
  return (
    <div className="] flex gap-[10px]">
      <HiChevronRight className="mt-[3px] h-5 w-5 shrink-0" />
      <p className="p text-sm text-gray-900 desktop:text-base">{children}</p>
    </div>
  );
}
