import { useRef, useState } from "react";

import { ButtonWithRef, PasswordInput, PasswordStrength } from "components";
import { HiLockClosed } from "react-icons/hi";
import { handleKeyDownSubmission } from "services";

export function ResetFormPassword({
  resetState,
  handleInput,
  proceedFn,
  pwdStrengthRef,
}) {
  const [err, setErr] = useState();
  const submitRef = useRef(null);

  const proceed = async () => {
    const error = await proceedFn();
    console.error(error);
    setErr(error);
  };

  const handlePasswordInput = (event) => {
    if (!!resetState.cPassword && event.target.value !== resetState?.password) {
      setErr({
        type: "match",
        message: "Passwords do not match.",
      });
    } else {
      setErr(null);
    }

    handleInput(event);
  };

  const handleConfirmPasswordInput = (event) => {
    if (!!event.target.value && event.target.value !== resetState?.password) {
      setErr({
        type: "match",
        message: "Passwords do not match.",
      });
    } else {
      setErr(null);
    }

    handleInput(event);
  };

  return (
    <>
      <div className="flex flex-col items-start self-stretch">
        <div className="flex flex-col gap-4 self-stretch">
          <div className="subtitle text-[14px] leading-normal text-gray-600">
            Set a new password
          </div>

          {!!err?.message && (
            <div className="text-[14px] font-medium text-red-600">
              {err.message}
            </div>
          )}

          <PasswordInput
            id="password"
            name="password"
            type="password"
            icon={HiLockClosed}
            placeholder="New password"
            required
            shadow
            onChange={handlePasswordInput}
            onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
            color={err?.type === "match" ? "failure" : "gray"}
          />

          <PasswordInput
            id="cPassword"
            name="cPassword"
            type="password"
            icon={HiLockClosed}
            placeholder="Confirm new password"
            required
            shadow
            onChange={handleConfirmPasswordInput}
            onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
            color={err?.type === "match" ? "failure" : "gray"}
          />
        </div>
      </div>

      {resetState?.password && <PasswordStrength ref={pwdStrengthRef} />}

      <div className="flex justify-end">
        <ButtonWithRef
          variant="small"
          ref={submitRef}
          type="submit"
          className="bg-black text-white"
          onClick={proceed}
        >
          Reset password
        </ButtonWithRef>
      </div>

      {/* <Link
        to={ROUTES.LOGIN}
        className="flex w-fit items-center gap-1 text-[14px] font-medium text-blue-600 hover:underline focus:underline"
      >
        Back to Login
        <HiReply className="h-4 w-4" />
      </Link> */}
    </>
  );
}
