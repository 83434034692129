import { useEffect, useRef, useState } from "react";

import { encode as base64_encode } from "base-64";
import { ButtonWithRef, FlowbiteTextInput, FormCard } from "components";
import { PasswordInput } from "components/input";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { FcGoogle } from "react-icons/fc";
import { HiLockClosed, HiUser } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import {
  AuthService,
  handleKeyDownInput,
  handleKeyDownSubmission,
} from "services";
import { ROUTES } from "variables";

// import { MicrosoftLogo } from "assets";

const INITIAL_LOGIN_STATE = {
  email: "",
  password: "",
  remember: false,
};

function LoginForm({ email, change, children }) {
  return (
    <>
      <FormCard
        title="Welcome"
        confirmData={
          !!email ? (
            <>
              <div className="flex flex-col gap-2">
                <div className="pb-1 text-sm leading-normal text-gray-600 tablet:w-[70%]">
                  Sign in to your account
                </div>

                <div className="flex flex-row items-center gap-2">
                  <HiUser className="text-icons h-5 w-5" />

                  <span className="font-semibold text-gray-900">{email}</span>

                  <button
                    className="text-link text-sm font-medium"
                    onClick={change}
                  >
                    Change
                  </button>
                </div>
              </div>
            </>
          ) : undefined
        }
      >
        <div className="input-form flex flex-col gap-[16px]">{children}</div>
      </FormCard>
    </>
  );
}

export function LoginEmailForm({
  email,
  setEmail,
  signin,
  initialEmailLookup,
}) {
  const { setLoading } = useLoadingState();
  const [err, setErr] = useState();
  const loginRef = useRef(null);

  useEffect(() => {
    if (!!initialEmailLookup) {
      signIn(initialEmailLookup);
    }
  }, [initialEmailLookup]);

  const handleInput = (event) => {
    setErr(null);
    setEmail(event.target.value);
  };

  const signIn = async (e = email) => {
    setErr(null);
    setLoading(true);
    let result = await signin(e);
    if (!!result?.err) {
      setErr({
        message: result.err,
      });
    }
    setLoading(false);
  };

  return (
    <LoginForm>
      <div className="subtitle text-[14px] leading-[21px] text-gray-600">
        Enter email to sign in
      </div>

      {!!err && (
        <div className="text-[14px] font-medium text-red-600">
          {err.message}
        </div>
      )}

      <div className="flex flex-col gap-2">
        <FlowbiteTextInput
          id="email"
          type="email"
          name="email"
          placeholder="Work Email Address"
          icon={HiUser}
          required
          onChange={handleInput}
          onKeyDown={(e) => handleKeyDownSubmission(e, loginRef)}
          color={
            err?.code === "sso" ||
            err?.code === "email" ||
            err?.code === "login"
              ? "failure"
              : "gray"
          }
        />
      </div>

      <div className="flex flex-col justify-start gap-4 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-16">
        <div className="mt-2 flex flex-row justify-between gap-4 tablet:items-center tablet:justify-start">
          <div
            className="cursor-pointer whitespace-nowrap text-sm font-medium text-primary outline-none hover:underline focus:underline"
            onClick={() => {
              window.location.href = ROUTES.RESET;
            }}
            onKeyDown={handleKeyDownInput}
            tabIndex={0}
          >
            Recover your account
          </div>
        </div>

        <ButtonWithRef
          variant="small"
          ref={loginRef}
          type="submit"
          onClick={() => signIn(email)}
        >
          Sign in
        </ButtonWithRef>
      </div>
    </LoginForm>
  );
}

export function LoginMethodForm({ email, methods, resetStep, select }) {
  return (
    <LoginForm email={email} change={resetStep}>
      <div className="flex w-full flex-col gap-[8px] laptop:flex-row">
        <ButtonWithRef
          className="w-full"
          variant="social"
          onClick={() => {
            select("email");
          }}
          disabled={!methods?.includes("email")}
        >
          <HiLockClosed className="h-5 w-5" />
          Use your password
        </ButtonWithRef>

        <ButtonWithRef
          className="w-full"
          variant="social"
          onClick={loginGG}
          disabled={!methods?.includes("google")}
        >
          <FcGoogle className="h-5 w-5" />
          Sign in with Google
        </ButtonWithRef>
      </div>

      <div className="flex flex-col justify-start gap-4 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-16">
        <div className="mt-2 flex flex-row justify-between gap-4 tablet:items-center tablet:justify-start">
          <div
            className="cursor-pointer whitespace-nowrap text-sm font-medium text-primary outline-none hover:underline focus:underline"
            onClick={() => {
              window.location.href = ROUTES.RESET;
            }}
            onKeyDown={handleKeyDownInput}
            tabIndex={0}
          >
            Recover your account
          </div>
        </div>

        <div></div>
      </div>
    </LoginForm>
  );
}

export function LoginPasswordForm({ email, resetStep }) {
  const [loginState, setLoginState] = useState(INITIAL_LOGIN_STATE);
  const [err, setErr] = useState();
  const { setLoading } = useLoadingState();
  const location = useLocation();
  const loginRef = useRef(null);

  useEffect(() => {
    setLoginState({
      ...loginState,
      email,
    });
  }, [email]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (!!params["sso_fail"]) {
      setErr({
        code: "sso",
        message: "Login failed. No account found.",
      });
    }
  }, [location.search]);

  const handleInput = (event) => {
    if (event.target.name === "remember") {
      setLoginState({
        ...loginState,
        remember: event.target.checked,
      });
    } else {
      setLoginState({
        ...loginState,
        [event.target.name]: event.target.value,
      });
    }
  };

  const login = async () => {
    setLoading(true);
    try {
      const { email, password, remember } = loginState;
      await AuthService.signIn({
        email,
        password,
        remember,
        signinCallback: () => {},
        confirmCallback: () => {
          window.location.href = `${ROUTES.ROOT}?c=${encodeURIComponent(
            base64_encode(
              JSON.stringify({
                confirm: true,
                email,
                resend: true,
              })
            )
          )}`;
        },
      });
      setErr(null);
    } catch (e) {
      setErr({
        message: e.message,
        code: "login",
      });
      setLoading(false);
    }
  };

  return (
    <LoginForm email={email} change={resetStep}>
      <div className="subtitle text-[14px] leading-[21px] text-gray-600">
        Enter password to sign in
      </div>

      {!!err && (
        <div className="text-[14px] font-medium text-red-600">
          {err.message}
        </div>
      )}

      <div className="flex flex-col gap-2">
        <PasswordInput
          id="password"
          type="password"
          name="password"
          placeholder="Password"
          icon={HiLockClosed}
          required
          onChange={handleInput}
          onKeyDown={(e) => handleKeyDownSubmission(e, loginRef)}
          color={
            err?.code === "sso" ||
            err?.code === "password" ||
            err?.code === "login"
              ? "failure"
              : "gray"
          }
        />
      </div>

      <div className="flex flex-col justify-start gap-4 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-16">
        <div className="mt-2 flex flex-row justify-between gap-4 tablet:items-center tablet:justify-start">
          {/* <div className="option-remember flex flex-row items-center gap-[8px] whitespace-nowrap">
              <CheckboxInput
                id="remember"
                name="remember"
                onChange={handleInput}
                onKeyDown={(event) => handleKeyDownInput(event)}
              />
              <Label
                htmlFor="remember"
                className="text-sm font-normal leading-none text-gray-600"
              >
                Remember me
              </Label>
            </div> */}

          <div
            className="cursor-pointer whitespace-nowrap text-sm font-medium text-primary outline-none hover:underline focus:underline"
            onClick={() => {
              window.location.href = ROUTES.RESET;
            }}
            onKeyDown={handleKeyDownInput}
            tabIndex={0}
          >
            Forgot password?
          </div>
        </div>

        <ButtonWithRef
          variant="small"
          ref={loginRef}
          type="submit"
          onClick={login}
        >
          Login
        </ButtonWithRef>
      </div>

      {/* <div
          className={`
          no-account flex flex-col gap-[2px]
          laptop:flex-row laptop:gap-[4px]
        `}
        >
          <div className="text-sm font-medium text-gray-900">
            Don't have an account yet?
          </div>

          <div className="text-sm font-medium text-primary">
            <Link to={ROUTES.CLAIM}>Claim your organisation</Link>
          </div>
        </div> */}
    </LoginForm>
  );
}

async function loginGG() {
  // await AuthService.signinWithGoogle();
  await AuthService.signinWithGoogle();
}
