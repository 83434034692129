import { ReactComponent as PageNotFoundImg } from "./images/404-img.svg";
// import animationBronzeWebM from "./animations/animation-level1-bronze.webm";
// import animationSilverWebM from "./animations/animation-level2-silver.webm";
// import animationGoldWebM from "./animations/animation-level3-gold.webm";
// import animationPlatinumWebM from "./animations/animation-level4-platinum.webm";
// import animationDiamondWebM from "./animations/animation-level5-diamond.webm";
import badgeBronzePng from "./images/badge-level1-bronze.png";
import badgeSilverPng from "./images/badge-level2-silver.png";
import badgeGoldPng from "./images/badge-level3-gold.png";
import badgePlatinumPng from "./images/badge-level4-platinum.png";
import badgeDiamondPng from "./images/badge-level5-diamond.png";
import certCirleWeb from "./images/cert-circle.webp";
import ClientApprovedJpg from "./images/client-approved.jpg";
import ClientsNeedsJpg from "./images/clients-needs.jpg";
import cscauBadgeWeb from "./images/cscau-badge.webp";
import PlatFormDashboardCTAWebp from "./images/dashboard-cta.webp";
import PlatFormDashboardCutWebp from "./images/dashboard-cut.webp";
import PlatformDashboardFramePng from "./images/dashboard-frame.png";
import PlatFormDashboardHeroWebp from "./images/dashboard-hero.webp";
import PlatformDashboardSlide1Png from "./images/dashboard-slide-1.png";
import PlatformDashboardSlide2Png from "./images/dashboard-slide-2.png";
import PlatformDashboardSlide3Png from "./images/dashboard-slide-3.png";
import heroWomanWeb from "./images/hero-woman.webp";
import iconBronzePng from "./images/icon-level1-bronze.png";
import iconSilverPng from "./images/icon-level2-silver.png";
import iconGoldPng from "./images/icon-level3-gold.png";
import iconPlatinumPng from "./images/icon-level4-platinum.png";
import iconDiamondPng from "./images/icon-level5-diamond.png";
import { ReactComponent as CyberCertLogoSecondary } from "./images/logo-secondary.svg";
import { ReactComponent as CyberCertLogo } from "./images/logo.svg";
import MicrosoftLogoPng from "./images/microsoft-logo.png";
import MSILogoPng from "./images/msi-logo.png";
import MSPWithClientJpg from "./images/msp-with-client.jpg";
import noMobileWeb from "./images/no-mobile.webp";
import ShieldBgPng from "./images/shield-bg.png";
import SMB1001Webp from "./images/smb1001.webp";
import ResourcesTabletPng from "./images/resources-tablets.png";

// export const AnimationBronze = animationBronzeWebM;
// export const AnimationSilver = animationSilverWebM;
// export const AnimationGold = animationGoldWebM;
// export const AnimationPlatinum = animationPlatinumWebM;
// export const AnimationDiamond = animationDiamondWebM;
export const BadgeBronze = badgeBronzePng;
export const BadgeSilver = badgeSilverPng;
export const BadgeGold = badgeGoldPng;
export const BadgePlatinum = badgePlatinumPng;
export const BadgeDiamond = badgeDiamondPng;

export { CyberCertLogo, CyberCertLogoSecondary, PageNotFoundImg };

export const CSCAUBadge = cscauBadgeWeb;
export const noMobile = noMobileWeb;
export const CertCircle = certCirleWeb;
export const HeroWoman = heroWomanWeb;
export const IconBronze = iconBronzePng;
export const IconSilver = iconSilverPng;
export const IconGold = iconGoldPng;
export const IconPlatinum = iconPlatinumPng;
export const IconDiamond = iconDiamondPng;
export const MSILogo = MSILogoPng;
export const MicrosoftLogo = MicrosoftLogoPng;
export const ClientsNeeds = ClientsNeedsJpg;
export const MSPWithClient = MSPWithClientJpg;
export const ClientApproved = ClientApprovedJpg;
export const ShieldBg = ShieldBgPng;
export const SMB1001Img = SMB1001Webp;
export const ResourcesTablet = ResourcesTabletPng;
export const PlatFormDashboardHeroImg = PlatFormDashboardHeroWebp;
export const PlatFormDashboardCutImg = PlatFormDashboardCutWebp;
export const PlatFormDashboardCTAImg = PlatFormDashboardCTAWebp;
export const PlatformDashboardFrame = PlatformDashboardFramePng;
export const PlatformDashboardSlide1 = PlatformDashboardSlide1Png;
export const PlatformDashboardSlide2 = PlatformDashboardSlide2Png;
export const PlatformDashboardSlide3 = PlatformDashboardSlide3Png;

export * from "./images/cscau-logos";
export * from "./images/grc-logos";
export * from "./images/partner-logos";
export * from "./images/testimonial-logos";

