const { API } = require("aws-amplify");

export const verifyPartnerAccount = async ({
  username,
  email,
  ABN,
  isActive,
  isSetup,
  phone,
  referralCode,
}) => {
  let result;
  try {
    const myInit = {
      queryStringParameters: {
        username,
        email,
        ABN,
        isActive,
        isSetup,
        phone,
        referralCode,
      },
    };
    result = await API.get("partner", "/verify", myInit);
  } catch (e) {
    console.error(e);
  }
  return result;
};
