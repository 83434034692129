// Function to detect OS
export const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  let os = "";
  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  else if (userAgent.indexOf("Mac") !== -1) os = "Mac";
  else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  else os = "Unknown";
  return os;
};

// Function to detect Browser
export const detectBrowser = () => {
  const userAgent = window.navigator.userAgent;
  let browser = "";
  if (userAgent.indexOf("Chrome") !== -1) browser = "Chrome";
  else if (userAgent.indexOf("Firefox") !== -1) browser = "Firefox";
  else if (userAgent.indexOf("Safari") !== -1) browser = "Safari";
  else if (userAgent.indexOf("Edge") !== -1) browser = "Edge";
  else browser = "Unknown";
  return browser;
};
