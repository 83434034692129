import { useEffect, useState } from "react";
import { LoadingScreenSpinner } from "./loading";

export function LoadingComponentOnload({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    // check fonts loaded
    await document.fonts.ready;
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return <>{loading === false ? <>{children}</> : <LoadingScreenSpinner />}</>;
}
