import { useEffect, useRef, useState } from "react";

import { ButtonWithRef } from "components";
import { HiRefresh } from "react-icons/hi";

const NUM_DIGITS = 6;

export function RegisterFormConfirm({
  registerState,
  setRegisterState = () => {},
  confirm,
  resend,
}) {
  const submitRef = useRef(null);
  const inputRefs = useRef([]);

  const [isLoading, setIsLoading] = useState();
  const [isFirstTry, setIsFirstTry] = useState(true);
  const [err, setErr] = useState();
  const [succ, setSucc] = useState();

  const handleChangeCode = (value) => {
    setErr(null);
    setRegisterState((registerState) => ({
      ...registerState,
      code: value,
    }));
  };

  const handleInput = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value !== "") {
      // Add digit to code
      const newCode = [...registerState.code];
      newCode[index] = value.slice(-1);
      handleChangeCode(newCode);

      // Move focus to the next input
      if (index < registerState.code.length - 1) {
        inputRefs.current[index + 1].select();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    // Enter key should submit
    if (
      event?.key === "Enter" &&
      !(event?.metaKey || event?.ctrlKey || event?.altKey)
    ) {
      verify(registerState?.code);
    }

    // Backspace and Delete keys
    if (event.keyCode === 8 || event.keyCode === 46) {
      // If backspace key is pressed and the current input box is empty, move focus to the previous inpu
      if (
        event.keyCode === 8 &&
        index > 0 &&
        registerState.code[index] === ""
      ) {
        inputRefs.current[index - 1].focus();
      }

      // If backspace or delete is pressed and the current input box is not empty, delete the digit
      else if (
        (event.keyCode === 8 || event.keyCode === 46) &&
        index >= 0 &&
        registerState.code[index] !== ""
      ) {
        const newCode = [...registerState.code];
        newCode[index] = "";
        // Shift remaining digits to the left
        for (let i = index; i < newCode.length - 1; i++) {
          newCode[i] = newCode[i + 1];
        }
        newCode[newCode.length - 1] = "";
        handleChangeCode(newCode);

        if (index > 0 && event.keyCode === 8) {
          inputRefs.current[index - 1].focus();
        }
      }
    }

    // Left and right arrow keys
    if (event.keyCode === 37) {
      // Left arrow key pressed, move focus to the previous input box
      event.preventDefault();
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (event.keyCode === 39) {
      // Right arrow key pressed, move focus to the next input box
      event.preventDefault();
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }

    // Stop CTRL+Z
    if (event.ctrlKey || event.metaKey) {
      // Check if Ctrl (or Command on Mac) is pressed
      if (event.key === "z") {
        // Check if Z key is pressed
        event.preventDefault(); // Prevent the default undo behavior
        // Do nothing
      }
    }
  };

  const handlePaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");

    // Must be only numbers
    if (!/[0-9\s]/.test(pasteData)) return;

    const numSpaces = 6 - index;
    const codeArray = pasteData
      .replace(/\s/g, "")
      .split("")
      .filter((char) => !isNaN(char))
      .slice(0, numSpaces);
    const newCode = [...registerState.code];
    codeArray.forEach((char, i) => {
      newCode[i + index] = char;
    });
    inputRefs?.current?.[codeArray?.length + index - 1]?.select();
    handleChangeCode(newCode);
  };

  const handleSelect = (event) => {
    event.preventDefault();
    event?.target?.setSelectionRange(
      event?.target?.value?.length,
      event?.target?.value?.length
    );
  };

  const verify = async () => {
    setIsLoading(true);
    setIsFirstTry(false);

    if (registerState.code.some((digit) => !digit)) {
      setErr({
        type: "code",
        msg: "Please enter verification code",
      });
      setIsLoading(false);
      return;
    }

    setSucc(null);
    setErr({
      type: "code",
      msg: await confirm(registerState?.email, registerState?.code?.join("")),
    });
    setIsLoading(false);
  };

  const resendCode = async () => {
    const error = await resend();
    if (!error) {
      setSucc("Resend code successful.");
      setErr(null);
    } else {
      setErr({
        type: "resend",
        msg: "Resend code unsuccessful. Please try again.",
      });
      setSucc(null);
    }
  };

  useEffect(() => {
    if (
      registerState?.code?.length === NUM_DIGITS &&
      registerState?.code.every((digit) => !!digit) &&
      isFirstTry
    )
      verify();
  }, [registerState?.code]);

  return (
    <>
      <div className="flex w-full flex-col justify-between gap-8 tablet:gap-0">
        <div className="flex flex-col gap-8">
          <div className="title text-[38px] font-semibold text-black">
            Verify your email
          </div>

          <div className="flex w-full text-sm leading-normal text-gray-600 tablet:w-[70%]">
            <span>
              We sent a verification code to your email{" "}
              <span className="text-blue-600">{registerState?.email}</span>
            </span>
          </div>
        </div>
      </div>

      <div className="input-form flex w-full flex-col gap-4">
        {/* {!!succ && (
          <div className="text-[14px] font-medium text-green-600">{succ}</div>
        )}

        {!!err && (
          <div className="text-[14px] font-medium text-red-600">{err.msg}</div>
        )} */}

        <div className="flex flex-row desktop:translate-x-[100px]">
          {!!succ && (
            <span className="text-[14px] font-medium text-green-600">
              {succ}
            </span>
          )}

          {!!err && (
            <span className="text-[14px] font-medium text-red-600">
              {err.msg}
            </span>
          )}
        </div>

        <div className="flex flex-row desktop:translate-x-[100px]">
          <span className="text-sm font-normal text-gray-600">
            Please input code for verification
          </span>
        </div>

        <div className="flex justify-end gap-2 self-stretch tablet:gap-4">
          {registerState.code.map((digit, index) => (
            <input
              key={index}
              disabled={isLoading}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              value={digit}
              className="h-[70px] w-full appearance-none rounded-md border-none p-3 text-center text-2xl font-medium caret-transparent outline outline-1 outline-gray-300 selection:bg-transparent focus:outline-gray-900 focus:ring-0 disabled:bg-gray-100 desktop:w-[10%]"
              onChange={(event) => handleInput(index, event)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              onPaste={(event) => handlePaste(index, event)}
              onSelect={handleSelect}
            />
          ))}
        </div>

        <div className="flex flex-col justify-start gap-4 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-16">
          <div className="text-[14px] font-semibold text-gray-900 desktop:translate-x-[100px]">
            <span
              className="cursor-pointer text-blue-600 outline-none hover:underline focus:underline"
              onClick={resendCode}
              tabIndex={0}
            >
              Resend Code
            </span>{" "}
            <HiRefresh className="inline-block text-blue-600" />
          </div>

          <ButtonWithRef
            variant="small"
            ref={submitRef}
            type="submit"
            onClick={verify}
          >
            Verify
          </ButtonWithRef>
        </div>
      </div>
    </>
  );
}
