import React from "react";
import { Modal } from "react-bootstrap";
export function WorkbookAndWorksheetDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">Workbook & Worksheet</h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Clip+02.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
        <p className="font-medium text-primary-dark">
          Your first step in the certification process is to download your
          workbook.
        </p>
        <p>
          It contains all the instructions and certification requirements that
          you need to have in place in your business to qualify for your
          certification.
        </p>
        <p>
          You can use the workbook as a tool to record and document your
          implementation of each requirement.
        </p>
        <p>
          Once all of the requirements are in place in your organisation, press
          "next step" to continue to step 2.
        </p>
      </div>
    </>
  );
}
