import { forwardRef, useImperativeHandle, useState } from "react";

import { passwordStrength } from "check-password-strength";

const passwordStrengthCheckConfigs = [
  {
    id: 0,
    value: "Weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 0,
    minLength: 1,
  },
  {
    id: 2,
    value: "Weak",
    minDiversity: 0,
    minLength: 4,
  },
  {
    id: 3,
    value: "Medium",
    minDiversity: 0,
    minLength: 8,
  },
  {
    id: 4,
    value: "Medium",
    minDiversity: 2,
    minLength: 8,
  },
  {
    id: 5,
    value: "Strong",
    minDiversity: 3,
    minLength: 8,
  },
  {
    id: 6,
    value: "Strong",
    minDiversity: 2,
    minLength: 10,
  },
  {
    id: 7,
    value: "Strong",
    minDiversity: 4,
    minLength: 10,
  },
];

const ACCEPTED_PASSWD_STRENGTH_ID = 3;

export const PasswordStrength = forwardRef((props, ref) => {
  const [pwdStrength, setPwdStrength] = useState();

  const getColor = () => {
    const percentage = pwdStrength?.percent || 0;
    if (percentage <= 40) {
      return "#E02424";
    } else if (percentage <= 70) {
      return "#dd9013";
    } else {
      return "#057A55";
    }
  };

  const getStrength = () => {
    const percentage = pwdStrength?.percent || 0;
    if (percentage <= 40) {
      return "Weak";
    } else if (percentage <= 70) {
      return "Medium";
    } else {
      return "Strong";
    }
  };

  useImperativeHandle(ref, () => ({
    check(password) {
      const strength = passwordStrength(password, passwordStrengthCheckConfigs);

      const result = {
        strength,
        percent: Math.min(
          Math.floor(
            ((strength.id * 1.0) / (passwordStrengthCheckConfigs.length - 1)) *
              100.0,
          ),
          100.0,
        ),
        accepted: strength.id >= ACCEPTED_PASSWD_STRENGTH_ID,
      };

      setPwdStrength(result);

      return result;
    },
  }));

  return (
    <>
      <div className="flex flex-col justify-between tablet:flex-row">
        <div className="text-[12px]">
          Password Strength:{" "}
          <span className="font-medium" style={{ color: getColor() }}>
            {" "}
            {getStrength()}{" "}
          </span>
        </div>
        <div className="minimum text-[10px] text-[#6C757D]">
          Minimum of 8 characters
        </div>
      </div>
      <div className="indicator border-1 h-[7px] overflow-hidden rounded-lg border-[#EBEBEB] bg-[#DFDFDF]">
        <div
          className={`bar transition-width border-1 h-full rounded-lg border-[#EBEBEB] bg-[#FFA412] duration-500 ease-out`}
          style={{
            width: `${pwdStrength?.percent || 0}%`,
            backgroundColor: getColor(),
          }}
        ></div>
      </div>
    </>
  );
});
