import { API } from "aws-amplify";

export const getOrganisationPublic = async (ABN) => {
  const myInit = {
    queryStringParameters: {
      ABN,
    },
  };

  return API.get("public", "/organisation", myInit);
};
