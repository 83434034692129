import React, { useState } from "react";
import { ResourcesTablet } from "assets";
import {
  HiArrowLeft,
  HiQuestionMarkCircle,
  HiShoppingCart,
} from "react-icons/hi";
import { RiBookmarkFill, RiCheckboxMultipleFill } from "react-icons/ri";
import { IoDocumentsSharp } from "react-icons/io5";
import { PiSignatureFill } from "react-icons/pi";
import { FaFileWaveform } from "react-icons/fa6";
import {
  FullCertificationProcessDocs,
  CertificationOverviewDocs,
  WorkbookAndWorksheetDocs,
  AttestingToRequirementsDocs,
  CreateAttestationLetterDocs,
  SignAttestationLetterDocs,
  CompletingCertificationDocs,
} from "./docs";
import { MdOutlineWeb } from "react-icons/md";
// import { FAQDocs } from "./docs/faq";
import { FaChartPie } from "react-icons/fa";
import { BsFillBookmarkCheckFill } from "react-icons/bs";

export function ResourcesPage() {
  const [selectedStep, setSelectedStep] = useState(null); // Track selected step

  // Adjusted object to map step numbers to their respective icons and titles
  const stepDetails = {
    Complete: { icon: HiShoppingCart, title: "Full Certification Process" },
    Steps: { icon: HiQuestionMarkCircle, title: "Certification Steps" },
    // FAQs: { icon: HiQuestionMarkCircle, title: "Frequently asked questions" },
  };

  // Adjusted function to render the correct component based on selectedStep
  const renderContent = () => {
    switch (selectedStep) {
      case "Complete":
        return <FullCertificationProcessDocs />;
      case "Steps":
        return <StepsPage setSelectedStep={setSelectedStep} />;
      case "Overview":
        return <CertificationOverviewDocs />;
      case "STEP 1":
        return <WorkbookAndWorksheetDocs />;
      case "STEP 2":
        return <AttestingToRequirementsDocs />;
      case "STEP 3":
        return <CreateAttestationLetterDocs />;
      case "STEP 4":
        return <SignAttestationLetterDocs />;
      case "STEP 5":
        return <CompletingCertificationDocs />;
      // case "FAQs":
      //   return <FAQDocs />;
      default:
        return null;
    }
  };

  return (
    <section className="flex flex-col gap-20 items-center w-full px-8 mx-auto tablet:max-w-[1080px] my-40">
      <div className="flex flex-col items-center justify-center gap-10 w-full laptop:max-w-screen-md">
        <h1 className="font-bold text-6xl">Resources</h1>
        <p className="text-lg text-gray text-center w-full tablet:w-[55%]">
          Our Resources provides step-by-step guides, FAQs, and expert tips to
          help you navigate and maximize the CyberCert platform effectively.
        </p>
      </div>

      <div className="flex flex-col gap-8">
        <div className="relative bg-gray-100 flex flex-col tablet:flex-row justify-between rounded-lg items-center">
          <div className="flex flex-col gap-2 w-full p-8 tablet:p-16">
            <p className="font-semibold text-xl">CyberCert Guides</p>
            <p className="text-gray w-full tablet:w-[70%]">
              Your comprehensive guide to achieving CyberCert certification and
              securing your organization against cyber threats. More to come
              soon!
            </p>
          </div>
          <div className="w-full h-fill flex flex-col items-center tablet:items-start">
            <img
              className="px-8 tablet:absolute tablet:bottom-0 tablet:right-16 tablet:w-1/2"
              src={ResourcesTablet}
              alt="Illustration of CyberCert Certification Platform"
            />
          </div>
        </div>

        {/* Selection section (hide when a step is selected) */}
        {!selectedStep && (
          <div className="grid grid-cols-1 tablet:grid-cols-3 gap-8">
            <StepBox
              stepNumber="Complete"
              icon={RiBookmarkFill}
              title="Full Certification Process"
              onClick={() => setSelectedStep("Complete")}
            />
            <StepBox
              stepNumber="Steps"
              icon={FaChartPie}
              title="Certification step by step"
              onClick={() => setSelectedStep("Steps")}
            />
            {/* <StepBox
              stepNumber="FAQs"
              icon={HiQuestionMarkCircle}
              title="Frequently asked questions"
              onClick={() => setSelectedStep("FAQs")}
            /> */}
          </div>
        )}

        {/* Content section (show only when a step is selected) */}
        {selectedStep && (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4 items-start">
              <div className="px-3 py-1 rounded-2xl bg-gray-200 text-gray-600 text-sm font-semibold">
                {selectedStep}
              </div>
              <h5 className="text-xl font-semibold flex flex-row gap-2 items-center">
                {stepDetails[selectedStep] && (
                  <>{stepDetails[selectedStep].title}</>
                )}
              </h5>
              <button
                className="flex flex-row gap-2 items-center text-gray hover:text-gray-900 font-medium"
                onClick={() => {
                  if (selectedStep.startsWith("STEP")) {
                    setSelectedStep("Steps"); // Go back to the StepsPage
                  } else {
                    setSelectedStep(null); // Reset step on back button
                  }
                }} // Updated click handler
              >
                <HiArrowLeft className="size-5" />
                {selectedStep.startsWith("STEP")
                  ? "Back to Certification Steps"
                  : "Back to All Resources"}{" "}
                {/* Updated button text */}
              </button>
            </div>

            {/* Render the component based on selected step */}
            {renderContent()}
          </div>
        )}
      </div>
    </section>
  );
}

const StepBox = ({ stepNumber, icon: Icon, title, className, onClick }) => (
  <div
    className="relative rounded-lg border border-gray-200 hover:bg-gray-900 cursor-pointer p-5 flex w-full text-gray-900 hover:text-white"
    onClick={onClick} // Add click handler to set the step
  >
    <div className="absolute top-5 left-5 px-3 py-1 rounded-2xl bg-gray-200 text-gray-600 text-sm font-semibold">
      {stepNumber}
    </div>
    <div className="flex flex-col gap-4 items-center w-full pt-4">
      <Icon className={`size-10 ${className} text-gray-400`} />
      <h5 className="text-base font-semibold">{title}</h5>
    </div>
  </div>
);

const StepsPage = ({ setSelectedStep }) => (
  <div className="flex flex-col gap-8">
    {/* <h2 className="text-4xl font-bold"></h2> */}
    <div className="grid grid-cols-1 tablet:grid-cols-3 gap-8">
      <StepBox
        stepNumber="Overview"
        icon={MdOutlineWeb}
        title="Certification Overview"
        onClick={() => setSelectedStep("Overview")}
      />
      <StepBox
        stepNumber="STEP 1"
        icon={IoDocumentsSharp}
        title="Workbook and Worksheet"
        onClick={() => setSelectedStep("STEP 1")}
      />
      <StepBox
        stepNumber="STEP 2"
        icon={RiCheckboxMultipleFill}
        title="Attesting to Requirements"
        onClick={() => setSelectedStep("STEP 2")}
      />
      <StepBox
        stepNumber="STEP 3"
        icon={FaFileWaveform}
        title="Create the Attestation Letter"
        onClick={() => setSelectedStep("STEP 3")}
      />
      <StepBox
        stepNumber="STEP 4"
        icon={PiSignatureFill}
        title="Sign the Attestation Letter"
        onClick={() => setSelectedStep("STEP 4")}
      />
      <StepBox
        stepNumber="STEP 5"
        icon={BsFillBookmarkCheckFill}
        title="Completing your Certification"
        onClick={() => setSelectedStep("STEP 5")}
      />
    </div>
  </div>
);
