import { openZEWidget } from "services";

export const NavBarMenuItems = [
  {
    id: "cybercert-menu-why-certify",
    content: (
      <span className="text-base text-gray-700 laptop:text-sm desktop:text-base">
        Why Certify
      </span>
    ),
  },
  {
    id: "cybercert-menu-about",
    content: (
      <span className="text-base text-gray-700 laptop:text-sm desktop:text-base">
        About
      </span>
    ),
  },
  {
    id: "cybercert-menu-contact",
    content: (
      <div
        className="w-full text-center text-base text-gray-700 laptop:text-sm desktop:text-base"
        onClick={openZEWidget}
      >
        Contact
      </div>
    ),
  },
];
