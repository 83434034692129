import { useState } from 'react';

import { ResetForm } from './reset-form';

export function Reset() {
  const [step, setStep] = useState("email"); // email / request / password

  return (
    <>
      <div className="content-body">
        <ResetForm step={step} setStep={setStep} />
      </div>
    </>
  );
}
