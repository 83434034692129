import { ButtonWithRef } from "components";
import { useWindowSize } from "contexts";
import { useNavigate } from "react-router-dom";
import { openZEWidget } from "services";
import { ROUTES } from "variables";

import { NavBarMenuItems as items } from "./navbar-menu-items";

export function NavbarMenu({ close, isOpen = false }) {
  const { wWidth } = useWindowSize();

  const navigate = useNavigate();

  return (
    <>
      <ul className="my-2 flex flex-col gap-4">
        <li>
          <ButtonWithRef
            variant="blank"
            disabled={wWidth >= 1024 || !isOpen}
            className="h-[41px] w-full focus:ring-4 focus:ring-blue-300 enabled:hover:bg-gray-100 enabled:hover:shadow"
            onClick={() => {
              close();
              openZEWidget();
            }}
          >
            {items.find((obj) => obj.id === "cybercert-menu-contact").content}
          </ButtonWithRef>
        </li>
        <li>
          <ButtonWithRef
            className="h-[41px] w-full focus:ring-4 focus:ring-blue-300 enabled:hover:bg-gray-100 enabled:hover:shadow"
            disabled={wWidth >= 1024 || !isOpen}
            variant="blank"
            onClick={() => {
              close();
              navigate("/resources");
            }}
          >
            Resources
          </ButtonWithRef>
        </li>
        <li>
          <ButtonWithRef
            className="h-[41px] w-full"
            disabled={wWidth >= 1024 || !isOpen}
            onClick={() => {
              close();
              navigate(ROUTES.LOGIN);
            }}
          >
            Login
          </ButtonWithRef>
        </li>
      </ul>
    </>
  );
}
