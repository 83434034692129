import { IconGold } from 'assets';

import { StepItem } from './step-item';

export function HomeSteps() {
  return (
    <div className="relative laptop:mx-[59px] laptop:max-w-[421px] laptop:shrink-0">
      <div className="relative z-10 flex flex-col gap-[30px]">
        <StepItem number={"1"} desc={"Choose your certification level"} />

        <StepItem number={"2"} desc={"Implement the requirements"} />

        <StepItem number={"3"} desc={"Complete the attestation"} />

        <div className="flex shrink-0 gap-[30px] pl-[4px] pt-[10px] laptop:pl-[7px]">
          <div className="h-[60px] w-[60px] shrink-0 laptop:h-[70px] laptop:w-[70px]">
            <img className="h-[60px] w-[60px] laptop:h-[70px] laptop:w-[70px]" src={IconGold} alt="Gold Badge Icon" />
          </div>

          <div className="text-xl font-semibold">
            Badge and certificate of completion
          </div>
        </div>
      </div>

      <div className="absolute left-[31px] top-[1px] z-0 h-[366px] w-[1px] bg-gray-400 tablet:h-[288px] laptop:left-[40.5px] laptop:top-[0.925px] laptop:h-[332px]"></div>
    </div>
  );
}
