import {
  isDemoHost,
  isDevHost,
  isLocalHost,
  isProdHost,
  isStagingHost,
} from "services";
import awsExports from "./aws-exports";

const [localRedirectSignIn, devRedirectSignIn, demoRedirectSignIn] =
  awsExports.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, devRedirectSignOut, demoRedirectSignOut] =
  awsExports.oauth.redirectSignOut.split(",");

let redirectSignIn = localRedirectSignIn,
  redirectSignOut = localRedirectSignOut,
  domain = awsExports.oauth.domain; // localhost

if (isLocalHost()) {
  redirectSignIn = `${window.location.origin}/`;
  redirectSignOut = `${window.location.origin}/`;
} else if (isDevHost()) {
  redirectSignIn = `${window.location.origin}/`;
  redirectSignOut = `${window.location.origin}/`;
} else if (isDemoHost()) {
  redirectSignIn = `${window.location.origin}/`;
  redirectSignOut = `${window.location.origin}/`;
} else if (isStagingHost()) {
  redirectSignIn = `${window.location.origin}/`;
  redirectSignOut = `${window.location.origin}/`;
} else if (isProdHost()) {
  redirectSignIn = `${window.location.origin}/`;
  redirectSignOut = `${window.location.origin}/`;
  domain = "oauth.cybercert.ai";
}

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn,
    redirectSignOut,
    domain,
  },
};

export default updatedAwsConfig;
