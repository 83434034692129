import { API } from "aws-amplify";
import { encode as base64_encode } from "base-64";

import { loadStripe } from "@stripe/stripe-js";

export const getProductCheckoutSession = ({
  ABN,
  item,
  partnerOffer,
  success_url,
  cancel_url,
}) => {
  const myInit = {
    queryStringParameters: {
      ABN,
      item,
      partnerOffer,
      success_url,
      cancel_url,
      operation: "SINGLE_CERT_PUBLIC",
    },
  };

  return API.get("stripe", `/public-checkout`, myInit);
};

export const openProductCheckoutSession = async ({
  ABN,
  item,
  partnerOffer,
  success_url,
  cancel_url,
}) => {
  const session = await getProductCheckoutSession({
    ABN,
    item,
    partnerOffer,
    success_url,
    cancel_url,
  });

  const { sessionId, err, publishableKey } = session;

  if (err === "FREE") {
    console.log("This item is free.");
    window.location.reload();
  } else {
    const stripe = await loadStripe(publishableKey);

    await stripe.redirectToCheckout({ sessionId });
  }
};

export const openProductCheckoutSessionForLevel = async ({
  ABN,
  level,
  partnerOffer,
}) => {
  const item = `LEVEL_${level}`;

  await openProductCheckoutSession({
    ABN,
    item,
    partnerOffer,
    success_url: `${window.location.origin}/?c=${encodeURIComponent(
      base64_encode(
        JSON.stringify({
          claim: true,
          ABN,
          purchased: true,
        })
      )
    )}`,
    cancel_url: window.location.origin,
  });
};
