import React from "react";
import { Modal } from "react-bootstrap";
export function AttestingToRequirementsDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">Attesting to Requrements</h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Clip+03.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
        <p className="font-medium text-primary-dark">
          Step 2 is where you confirm you have implemented all of the
          certification requirements in your organisation.
        </p>
        <p>
          Go through each requirement one by one and tick them to confirm they
          have been implemented.
        </p>
        <p>
          To certify, you must implement each requirement, unless the not
          applicable option is available. Enter the name of the person who is
          attesting to the implementation of each requirement and press "next
          requirement" to continue.
        </p>
        <p>
          Once all the requirements have been ticked off and you have reviewed
          the final checklist, click confirm to move to Step 3.
        </p>
      </div>
    </>
  );
}
