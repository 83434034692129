import { Navigate, useParams } from "react-router-dom";
import { ROUTES } from "variables";

const CODES = {
  "2je3bzQ": "https://www.cybercert.ai/en-au/ref/X7gH4vR9pZ",
  "b3fV9qL": "https://partners.cybercert.ai/en-au/msp/b3fV9qL1eV", // SMBiT
  "3be3bzB": "/CSCAU_CyberCert_Overview.pdf",
  "1ct7bU": "/CyberCert_Partner_Program_Huntress.pdf",
  "6fkm9bx": "/CyberCert_Partner_Program_SentinelOne.pdf",
  capmo: "/Cyber_Assurance_Program_(CAP)_Membership_v1.0.pdf",
  capfr: "/Cyber_Assurance_Program_(CAP)_Franchises_V1.0.pdf",
  capsc: "/Supplier_Cyber_Assurance_Program_(SCAP)_V1.2.pdf",
  capmsp: "/Cyber_Assurance_Program_(CAP)_MSPs_V1.1.pdf",
};

export function QRPage() {
  const { code } = useParams();

  const redirect = CODES?.[code];

  if (redirect) {
    window.location = redirect;
  } else {
    return <Navigate to={ROUTES[404]} />;
  }

  return null;
}
