const { API } = require("aws-amplify");

export const verifyUserEmail = async ({ email, ABN }) => {
  const myInit = {
    queryStringParameters: {
      email,
      ABN,
    },
  };
  const result = await API.get("user", "/verify/email", myInit);
  return result;
};
