import { verifyPartnerAccount } from "api";
import { LoadingScreenSpinner } from "components";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "variables";

export function PartnerFormRefPage() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    let code = params?.code;

    let verify = await verifyPartnerAccount({
      referralCode: code,
    });

    if (!!verify?.referral) {
      sessionStorage.setItem(
        "CAP_REFERRAL_CODE",
        JSON.stringify(verify.referral?.code)
      );
      sessionStorage.setItem(
        "CAP_REFERRAL_CERT_BUNDLE",
        JSON.stringify(verify.referral?.certBundle)
      );
      sessionStorage.setItem(
        "CAP_REFERRAL_LINK_TO_REFERRER",
        JSON.stringify(verify.referral?.linkToReferrer)
      );
    }

    navigate(ROUTES.PARTNER_FORM);
  }

  return (
    <>
      <LoadingScreenSpinner />
    </>
  );
}
