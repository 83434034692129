import { useState } from "react";

import {
  ABNResult,
  FlowbiteSpinner,
  FlowbiteTextInput,
  HoneypotForm,
  PopupModal,
} from "components";
import { Modal } from "flowbite-react";
import { HiOfficeBuilding } from "react-icons/hi";
import { ABNService, openZEWidget, sanitizeInputWithSpace } from "services";

export function CheckoutModal({
  active,
  setActive,
  ABN,
  setABN,
  continueFn = async () => {},
  checkoutLevel,
}) {
  const [abnSearchRes, setAbnSearchRes] = useState();
  const [abnSearchErr, setAbnSearchErr] = useState();
  const [abnAvailable, setAbnAvailable] = useState(false);
  const [abnRegistered, setAbnRegistered] = useState(false);
  const [orgSearchRes, setOrgSearchRes] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const submitSearch = async (event) => {
    event.preventDefault();
    console.log("submitSearch");

    await searchABN(ABN);
  };

  const searchABN = async () => {
    setABN(sanitizeInputWithSpace(ABN));
    setAbnSearchErr(false);
    setIsLoading(true);
    const lookupResult = await ABNService.lookup({ abn: ABN });

    const existedOrganisation = lookupResult?.organisation;
    let result = lookupResult.result;
    let customerLevel = existedOrganisation?.customer?.level || 0;

    setOrgSearchRes({
      ...existedOrganisation,
      certifiedStatus:
        // if customer is not certified or less than partner's offered level, -1,
        // if equal, 0, if higher, 1
        !existedOrganisation || customerLevel < checkoutLevel
          ? -1
          : customerLevel === checkoutLevel
            ? 0
            : 1,
    });

    if (!!lookupResult.error) {
      setAbnSearchErr(lookupResult.error);
    } else if (!existedOrganisation) {
      setAbnAvailable(true);
      setAbnRegistered(false);
    } else if (existedOrganisation.confirmed) {
      setAbnSearchErr(result);
      setAbnRegistered(true);
    } else {
      setAbnAvailable(true);
      setAbnRegistered(false);
    }

    setAbnSearchRes(result);
    setIsLoading(false);

    return result;
  };

  const resetSearch = () => {
    setAbnSearchRes(undefined);
    setAbnRegistered(false);
    setAbnSearchErr(false);
    setABN("");
  };

  const proceed = async () => {
    setIsLoading(true);
    await continueFn();
    setIsLoading(false);

    setAbnSearchRes(undefined);
    setAbnRegistered(false);
    setAbnSearchErr(false);
  };

  return (
    <PopupModal
      dismissible={true}
      show={active}
      size={"md-static"}
      onClose={() => {
        resetSearch();
        setActive(false);
      }}
    >
      {/* <Modal.Header>Get Started</Modal.Header> */}
      <Modal.Body>
        <HoneypotForm
          className="input-form flex flex-col gap-[16px]"
          onSubmit={!!abnSearchRes ? proceed : submitSearch}
        >
          <div className="flex flex-col items-start gap-[51px] self-stretch">
            <h2 className="h2 title w-full align-middle text-[38px] font-bold text-black laptop:h-[53px]">
              Get started
            </h2>
            <div className="flex flex-col gap-4 self-stretch">
              {abnRegistered && orgSearchRes?.certifiedStatus !== -1 ? (
                <>
                  <ABNResult
                    abnSearchRes={abnSearchRes}
                    isError={true}
                    resetSearch={resetSearch}
                  />
                </>
              ) : (
                <>
                  {!!abnSearchRes ? (
                    <>
                      <ABNResult
                        abnSearchRes={abnSearchRes}
                        isError={false}
                        resetSearch={resetSearch}
                      />
                    </>
                  ) : (
                    <>
                      <div className="subtitle text-[14px] leading-normal text-gray-600">
                        Enter the ABN of the organisation that you <br />
                        are wanting to get certified.
                      </div>

                      {!!abnSearchErr && (
                        <>
                          <div className="text-sm font-medium text-red-600">
                            ABN is invalid. Please try again.
                          </div>
                        </>
                      )}

                      <FlowbiteTextInput
                        disabled={isLoading}
                        id="ABN"
                        name="abn"
                        type="text"
                        icon={HiOfficeBuilding}
                        placeholder="Enter your ABN"
                        required
                        shadow
                        onChange={(event) => {
                          setABN(event.target.value);
                          setAbnSearchErr(false);
                        }}
                        // onKeyPress={filterNumberAndSpaceKeyPress}
                        // onPaste={filterNumberAndSpacePaste}
                        color={!!abnSearchErr ? "failure" : "gray"}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {abnRegistered && orgSearchRes?.certifiedStatus !== -1 ? (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col text-[14px] text-gray-900">
                <div>Cannot find your organisation or it's been claimed?</div>
                <div
                  onClick={openZEWidget}
                  className="outline-none hover:underline focus:underline"
                  tabIndex={0}
                >
                  <span className="cursor-pointer font-medium text-blue-600">
                    Get Help
                  </span>
                </div>
              </div>

              <div>
                {orgSearchRes?.certifiedStatus === 0 && (
                  <>
                    This account has already claimed this cert for{" "}
                    {new Date().getFullYear()}. You can login to your account to
                    view other levels available for purchase.
                  </>
                )}

                {orgSearchRes?.certifiedStatus === 1 && (
                  <>
                    This account has already claimed a higher certification
                    level for {new Date().getFullYear()}. Login to your account
                    to view the certification
                  </>
                )}

                {orgSearchRes?.certifiedStatus === -1 && (
                  <>
                    This account has already been claimed. Please login to your
                    account to purchase.
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {!!abnSearchRes ? (
                <>
                  <button
                    className="btn-primary h-[44px] text-sm font-medium"
                    disabled={
                      (!abnAvailable && orgSearchRes?.certifiedStatus !== -1) ||
                      isLoading
                    }
                  >
                    {isLoading ? <FlowbiteSpinner /> : "Continue to checkout"}
                  </button>

                  <div className="flex flex-col text-[14px] text-gray-900">
                    <div>
                      Cannot find your organisation or it's been claimed?
                    </div>
                    <div
                      onClick={openZEWidget}
                      className="outline-none hover:underline focus:underline"
                      tabIndex={0}
                    >
                      <span className="cursor-pointer font-medium text-blue-600">
                        Get Help
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="btn-primary h-[44px] text-sm font-medium"
                    disabled={isLoading}
                  >
                    {isLoading ? <FlowbiteSpinner /> : "Search ABN"}
                  </button>

                  <div className="text-[14px] text-gray-900">
                    Can’t find your ABN?{" "}
                    <span
                      onClick={openZEWidget}
                      className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                      tabIndex={0}
                    >
                      Get Help
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </HoneypotForm>
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </PopupModal>
  );
}
