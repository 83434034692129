import { usePartnerOffer } from "contexts";
import { HiArrowDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export function PartnerOfferBanner() {
  const navigate = useNavigate();

  const { partnerOffer } = usePartnerOffer();

  const handleNavigate = () => {
    const lang = localStorage.getItem("LANG");
    navigate(`/${lang}/`, {
      state: {
        hash: "purchase",
      },
    });
  };

  if (!partnerOffer?.ABN) return;
  return (
    <div className="border-secondary flex h-16 items-center justify-center gap-3 self-stretch border-b bg-primary-dark px-5">
      {partnerOffer?.favicon ? (
        <img
          className="h-[26px] w-[26px] rounded-full border border-gray-200 bg-white"
          src={partnerOffer?.favicon}
          alt={`${partnerOffer?.name} Favicon`}
        />
      ) : partnerOffer?.logo ? (
        <img
          className="h-[26px]"
          src={partnerOffer?.logo}
          alt={`${partnerOffer?.name} Logo`}
        />
      ) : null}

      <span className="text-xs font-semibold text-white">
        {!partnerOffer?.logo || partnerOffer?.favicon
          ? partnerOffer?.name + " "
          : null}
        has offered your organisation a {partnerOffer?.discount}% discount on
        purchase
      </span>

      <button
        className="btn-green flex flex-shrink-0 items-center gap-0.5 rounded-full px-2 py-1"
        onClick={handleNavigate}
      >
        <span className="text-xs font-bold">View Offer</span>
        <HiArrowDown className="h-3 w-3" />
      </button>
    </div>
  );
}
