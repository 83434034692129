import {
  setLoadingAction,
  setLoadingMsgAction,
} from "middleware/redux/slices/loadingSlice";
import { useDispatch, useSelector } from "react-redux";

export const useLoadingState = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const setLoadingMsg = (value) => dispatch(setLoadingMsgAction(value));
  const setLoading = (value) => {
    if (value === false) {
      setLoadingMsg(null);
    }
    dispatch(setLoadingAction(value));
  };
  const setLoadingWithDefaultMsg = () => {
    setLoading(true);
    // setLoadingMsg("Loading your request, please wait.");
  };
  return { loading, setLoading, setLoadingMsg, setLoadingWithDefaultMsg };
};
