import { useState } from "react";

import { HiCheck } from "react-icons/hi";
import { openZEWidget } from "services";

export function ResetFormRequest({ state, resend }) {
  const [succ, setSucc] = useState();
  const [err, setErr] = useState();

  const resendFn = async () => {
    const error = await resend();

    if (!error) {
      setSucc("Email has been resent successfully.");
      setErr(null);
    } else {
      setErr("Failed to resend email. Please try again.");
      setSucc(null);
    }
  };

  return (
    <>
      <div className="flex flex-col items-start">
        <div className="flex flex-col gap-4 self-stretch">
          <div className="bg-green-50 rounded-full w-fit p-4 tablet:-mt-10">
            <HiCheck className="h-8 w-8 text-green-600" />
          </div>

          {!!succ && (
            <div className="text-[14px] font-medium text-green-600">{succ}</div>
          )}
          {!!err && (
            <div className="text-[14px] font-medium text-red-600">{err}</div>
          )}

          <div className="subtitle text-[14px] leading-normal text-gray-600">
            An email containing further instructions to reset your password has
            been sent to <span className="text-blue-600">{state?.email}</span>
            <br />
            <br />
            If you did not receive this email, please{" "}
            <span
              className="cursor-pointer text-blue-600 hover:underline focus:underline"
              onClick={resendFn}
            >
              resend
            </span>{" "}
            or{" "}
            <span
              className="cursor-pointer text-blue-600 hover:underline focus:underline"
              onClick={openZEWidget}
            >
              contact our support
            </span>{" "}
            for assistance.
          </div>
        </div>
      </div>

      {/* <Link
        to={ROUTES.LOGIN}
        className="flex w-fit items-center gap-1 text-[14px] font-medium text-blue-600 hover:underline focus:underline"
      >
        Back to Login
        <HiReply className="h-5 w-5" />
      </Link> */}
    </>
  );
}
