const { API } = require("aws-amplify");

export const verifyPartnerOffer = async ({ code }) => {
  const myInit = {
    queryStringParameters: {
      id: code,
    },
  };
  const result = await API.get("partner", "/offer/verify", myInit);
  // console.log(result);
  return result;
};
