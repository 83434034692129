import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {
  acceptPartnerNewUserInvite,
  postSignupWithSSO as postSignupWithSSO_API,
  verifyPartnerAccount,
} from "api";
import { pushLoginEvent } from "api/functions/user/event";
import { Auth } from "aws-amplify";
import { encode as base64_encode } from "base-64";
import {
  getAppDestination,
  sanitizeInputWithSpace,
  sleep,
} from "services/utils";
import { ROUTES } from "variables";

/**
 * Get current auth user
 * @returns current auth user { id, email, abn, group}
 */
const getCurrentAuthUser = async () => {
  try {
    const ret = Auth.currentAuthenticatedUser({ bypassCache: true }).then(
      (session) => {
        return {
          id: session.username,
          email: session.attributes.email,
          abn: session.attributes["custom:abn_number"],
          purchase: session.attributes["custom:purchase"],
          group:
            session.signInUserSession?.accessToken?.payload["cognito:groups"] ||
            [],
        };
      }
    );
    return ret;
  } catch (e) {
    return null;
  }
};

/**
 * Get current login session
 *
 * @returns current Cognito auth session
 */
const getCurrentAuthSession = async () => {
  try {
    const ret = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return ret;
  } catch (e) {
    return null;
  }
};

/**
 * Get current auth user id and email
 *
 * @returns {id, email}
 */
const getCurrentAuthUserInfo = async () => {
  try {
    const result = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then((session) => {
      return {
        id: session.attributes.sub,
        username: session.username,
        email: session.attributes.email,
        abn: session.attributes["custom:abn_number"],
        purchase: session.attributes["custom:purchase"],
      };
    });
    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Sign in with email / password credentials
 * 1. sign user in with email and password
 * 2. check if user is a valid partner
 * 3. redirect to app with encoded login data
 *
 * @param {*} email
 * @param {*} password
 * @param {*} remember
 * @param {*} signinCallback
 * @param {*} confirmCallback
 * @param {*} successUrl
 * @returns
 */
const signIn = async (signInInput) => {
  let {
    email = "",
    password = "",
    remember = false,
    signinCallback = () => {},
    confirmCallback = () => {},
    successUrl = "",
  } = signInInput || {};
  try {
    if (!email) {
      let error = new Error("Work email address is invalid.");
      error.code = "email";
      throw error;
    }
    if (!password) {
      let error = new Error("Password is invalid.");
      error.code = "password";
      throw error;
    }
    let user = await getCurrentAuthUserInfo();
    if (!!user?.id) {
      sessionStorage.setItem(
        "POST_SIGNOUT",
        JSON.stringify({
          signInInput,
        })
      );
      await Auth.signOut();
    } else {
      let result = await Auth.signIn(email, password);
      let mfaRequired = result.challengeName === "SOFTWARE_TOKEN_MFA";
      postSignIn({
        email,
        password,
        mfaRequired,
        remember,
        successUrl,
      });
      signinCallback();
    }
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      confirmCallback();
      return;
    } else if (
      error.code === "NotAuthorizedException" ||
      error.code === "UserNotFoundException"
    ) {
      // throw error;
    }
    console.error("Please provide valid email and password.");
    throw error;
  }
};

/**
 * Sign up with email / password credentials
 * 1. sign user up with email and password
 * 2. check if user is a valid partner
 * 3. redirect to app with encoded login data
 *
 * @param {*} input: { email, password, firstName, lastName, abn }
 * @param {*} confirmCallback
 * @param {*} onSignupCallback
 */
const signUp = async (
  input = {},
  confirmCallback = () => {},
  onSignupCallback = () => {}
) => {
  let result;
  try {
    const { email, password, firstName, lastName, abn } = input;
    if (!email || !password || !firstName || !lastName || !abn) {
      throw new Error("Missing details");
    }
    let stripePurchaseSession = sessionStorage.getItem(
      "STRIPE_PURCHASE_SESSION"
    );
    result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
        "custom:abn_number": sanitizeInputWithSpace(abn),
        "custom:purchase": stripePurchaseSession,
        "custom:location_origin": window.location.origin,
      },
    });
  } catch (error) {
    if (error.code === "UsernameExistsException") {
      throw error;
    }
    console.error("Failed to register new account.");
    console.error(error);
  }
  if (!result) {
    throw new Error("register failed");
  }
  if (!result.userConfirmed) {
    confirmCallback();
  }
  onSignupCallback(result);
};

/**
 * Confirm signup with username (email) / code credentials
 *
 * @param {*} username
 * @param {*} code
 * @param {*} confirmCallback
 */
const confirmSignup = async (username, code, confirmCallback = () => {}) => {
  try {
    await Auth.confirmSignUp(username, code);
    await sleep(3000);
    confirmCallback();
  } catch (error) {
    console.error("Failed to confirm register information.");
    throw error;
  }
};

/**
 * Resend confirmation code
 *
 * @param {*} username
 */
const resendConfirmationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
  } catch (error) {
    console.error("Failed to resend code.");
  }
};

/**
 * Google SSO
 */

/**
 * Sign in with SSO
 * 1. get current auth user info
 * 2. if user is logged in, sign out and redirect to SSO
 * 3. if user is not logged in, redirect to SSO
 *
 * @param {*} provider: CognitoHostedUIIdentityProvider
 * @param {*} customState: JSON.stringify({ name, data })
 */
const signInWithSSO = async ({ provider, customState }) => {
  let signInInput = { provider, customState };
  try {
    let user = await getCurrentAuthUserInfo();
    if (!!user?.id) {
      sessionStorage.setItem(
        "POST_SIGNOUT",
        JSON.stringify({
          signInInput,
        })
      );
      await Auth.signOut();
    } else {
      Auth.federatedSignIn(signInInput);
    }
  } catch (e) {
    console.error(e);
  }
};

const signupWithGoogle = async ({ ABN, userInvite = {}, claimInfo }) => {
  let purchase = sessionStorage.getItem("STRIPE_PURCHASE_SESSION");
  await signInWithSSO({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: `{
      "name": "SSO_POST_SIGNUP",
      "data": {
        "ABN": "${ABN}", 
        "provider": "Google",
        "claimInfo": ${claimInfo}
        ${!!purchase ? `, "purchase": ${JSON.stringify(purchase)}` : ""}
        ${!!userInvite?.invite ? `, "userInvite": ${JSON.stringify(userInvite)}` : ""}
      }
    }`,
  });
};

const signinWithGoogle = async (input) => {
  let { successUrl } = input || {};
  await signInWithSSO({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: `{
        "name": "SSO_POST_SIGNIN",
        "data": {
          "status": "OK",
          "successUrl": "${successUrl || ""}"
        }
      }`,
  });
};

/**
 * Check logged in (SSO) user matches user invitation's email
 * if user.email matches userInvite.email, return call postsignup function
 *
 * @param {Object} userInvite
 */
const postSignupWithSSOCheckUserInvite = async ({
  ABN,
  provider,
  userInvite = {},
}) => {
  const user = await getCurrentAuthUserInfo();
  if (!!user) {
    if (
      (!!userInvite?.email && user.email === userInvite.email) ||
      !userInvite?.email
    ) {
      await acceptPartnerNewUserInvite({
        userInvites: [userInvite?.invite],
      });
      let result = await postSignupWithSSO({ ABN, provider });
      return result;
    } else {
      window.location.href = `${ROUTES.ROOT}?c=${encodeURIComponent(
        base64_encode(JSON.stringify(userInvite))
      )}`;
    }
  }
};

/**
 * Post SSO
 */
const postSignupWithSSO = async ({
  ABN,
  purchase,
  provider,
  setSession = () => {},
}) => {
  try {
    let sess = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    let result = await postSignupWithSSO_API({
      username: sess.username,
      ABN,
      firstName: sess.attributes["given_name"],
      lastName: sess.attributes["family_name"],
      email: sess.attributes["email"],
      purchase,
    });

    if (!!result?.error) {
      return result;
    } else {
      ssoPostSignin();
    }
  } catch (e) {
    console.error(e);
  }
};

const forgotPassword = async (email, callbackFn = () => {}) => {
  let result;
  try {
    result = await Auth.forgotPassword(email);
    callbackFn();
  } catch (e) {
    console.error(e);
    throw e;
  }
  return result;
};

const forgotPasswordConfirmReset = async (
  email,
  code,
  newPwd,
  callbackFn = () => {}
) => {
  let user;
  try {
    user = await Auth.signIn(email, newPwd); // should fail
  } catch (err) {
    //
  }
  if (!!user) {
    const err = new Error("Please enter a password you haven't used before.");
    err.code = "PasswordInUse";
    throw err;
  }
  return Auth.forgotPasswordSubmit(email, code, newPwd).then((data) => {
    callbackFn(newPwd);
  });
};

/**
 * Post signin with SSO
 * 1. get current auth user info
 * 2. verify partner account
 * 3. redirect to app destination
 *
 * @returns
 */
const ssoPostSignin = async (input) => {
  let { successUrl } = input || {};

  await postSignIn({
    successUrl,
  });
};

const postSignIn = async ({
  email,
  password,
  mfaRequired,
  remember,
  successUrl,
}) => {
  const user = await getCurrentAuthUserInfo();

  if (!!user) {
    await pushLoginEvent();

    let verify = await verifyPartnerAccount({});
    const isValidPartner = verify?.result || false;
    let appDestination = getAppDestination(isValidPartner);

    if (!!email && !!password) {
      window.location.href = `${appDestination}/?c=${encodeURIComponent(
        base64_encode(
          JSON.stringify({
            login: "email",
            rememberMe: remember,
            successUrl,
            ...(mfaRequired ? { u: email, p: password } : {}),
          })
        )
      )}`;
    } else if (user?.username?.includes("google")) {
      window.location.href = `${appDestination}/?c=${encodeURIComponent(
        base64_encode(
          JSON.stringify({
            login: "Google",
            successUrl,
          })
        )
      )}`;
    }
  } else if (mfaRequired && !!email && !!password) {
    let verify = await verifyPartnerAccount({ email });
    const isValidPartner = verify?.result || false;
    let appDestination = getAppDestination(isValidPartner);

    let partnerInvite = sessionStorage.getItem(
      "ACCEPT_PARTNER_INVITE_BY_LOGIN"
    );
    sessionStorage.removeItem("ACCEPT_PARTNER_INVITE_BY_LOGIN");

    window.location.href = `${appDestination}/?c=${encodeURIComponent(
      base64_encode(
        JSON.stringify({
          login: "email",
          rememberMe: remember,
          successUrl,
          partnerInvite,
          ...(mfaRequired ? { u: email, p: password } : {}),
        })
      )
    )}`;
  }
};

/**
 * Ensure there is no active auth session
 */
export const ensureNoAuth = async () => {
  let user = await getCurrentAuthUserInfo();

  if (!!user) {
    await Auth.signOut();
  }
};

export const AuthService = {
  signUp,
  confirmSignup,
  resendConfirmationCode,
  signIn,
  signupWithGoogle,
  signinWithGoogle,
  postSignupWithSSO,
  forgotPassword,
  forgotPasswordConfirmReset,
  ssoPostSignin,
  getCurrentAuthUser,
  getCurrentAuthSession,
  getCurrentAuthUserInfo,
  postSignupWithSSOCheckUserInvite,
  signInWithSSO,
  postSignIn,
  ensureNoAuth,
};
