import { useRef, useState } from "react";

import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { ButtonWithRef, FlowbiteSpinner, FlowbiteTextInput } from "components";
import { HiArrowRight, HiCheck, HiMail, HiX } from "react-icons/hi";
import { getDashboardPage, handleKeyDownSubmission } from "services";
import { Spinner } from "flowbite-react";

const LEVEL_ICONS = [
  null,
  IconBronze,
  IconSilver,
  IconGold,
  IconPlatinum,
  IconDiamond,
];
const LEVELS = [null, "Bronze", "Silver", "Gold", "Platinum", "Diamond"];

export function T2ConfirmLoginWithInvite({
  inviteVerifyEmail,
  setInviteVerifyEmail,
  orgSearchRes,
  partnerOrg,
  confirmLogin,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmSelection, setConfirmSelection] = useState(null);
  const [error, setError] = useState();
  const submitRef = useRef(null);

  async function login() {
    setError(null);
    setIsLoading(true);
    let result = await confirmLogin();
    if (!!result?.error) {
      setError(result?.error.message || "Unknown error");
    }
    setIsLoading(false);
  }

  return (
    <div className="input-form flex flex-col gap-[16px]">
      <div className="flex flex-col items-start gap-[32px] self-stretch">
        <div className="flex flex-col gap-[20px] self-stretch rounded border-[1px] border-yellow-600 bg-yellow-50 p-[20px] text-yellow-700">
          <div className="text-base font-medium">
            New invitation will impact your current in-progress certification.
          </div>

          <div className="flex flex-row items-center gap-3 font-semibold text-black">
            <img
              src={LEVEL_ICONS[orgSearchRes?.customer?.level]}
              alt={`SMB1001 ${LEVELS[orgSearchRes?.customer?.level]} certification`}
              width={20}
              height={20}
            />
            <span className="text-sm font-semibold text-yellow-700">
              {LEVELS[orgSearchRes?.customer?.level]}
            </span>
          </div>

          <div>
            You currently have an in-progress certification for {LEVELS[orgSearchRes?.customer?.level]}.
            Accepting the new invite will cancel and remove your current
            certification.
          </div>

          <div>Do you wish to continue?</div>

          <div className="flex flex-row gap-2">
            <div
              className={` ${confirmSelection === true ? "border-[2px] rounded-[10px] border-yellow-500" : ""} ${confirmSelection === false ? "opacity-50" : ""}`}
            >
              <button
                className={`flex w-fit cursor-pointer flex-row items-center gap-1 rounded-[8px] px-[12px] py-[8px] text-white focus:underline enabled:hover:opacity-80 bg-yellow-700`}
                disabled={isLoading}
                onClick={() => setConfirmSelection(true)}
              >
                <HiCheck />
                <span className="text-sm font-semibold">Yes</span>{" "}
              </button>
            </div>

            <div
              className={` ${confirmSelection === true ? "opacity-50" : ""} ${confirmSelection === false ? "border-[2px] rounded-[10px] border-black" : ""}`}
            >
              <button
                className={`flex w-fit cursor-pointer flex-row items-center gap-1 rounded-[8px] px-[12px] py-[8px] text-black focus:underline enabled:hover:opacity-80 bg-white`}
                disabled={isLoading}
                onClick={() => setConfirmSelection(false)}
              >
                <HiX />
                <span className="text-sm font-semibold">No</span>{" "}
              </button>
            </div>
          </div>
        </div>

        {confirmSelection === false && (
          <>
            <div>
              You have declined the Gold Certification invitation. You can now
              return to your dashboard.{" "}
            </div>

            <button
              className={`flex w-fit cursor-pointer flex-row items-center gap-2 rounded-[8px] px-[12px] py-[8px] text-black focus:underline enabled:hover:opacity-80 bg-white border-[1px] border-[#E5E7EB]`}
              disabled={isLoading}
              onClick={() => (window.location.href = `${getDashboardPage()}`)}
            >
              <HiArrowRight className="text-[#9CA3AF]" />
              <span className="text-sm font-semibold">
                Go to Dashboard
              </span>{" "}
            </button>
          </>
        )}

        {confirmSelection === true && (
          <>
            <div className="flex flex-col gap-[16px] w-full">
              <div>To start, please enter your email address.</div>

              <FlowbiteTextInput
                autoComplete={"email"}
                id="email"
                className="border-none w-full"
                type="email"
                name="email"
                placeholder="Work email address"
                value={inviteVerifyEmail}
                disabled={isLoading}
                icon={HiMail}
                required
                shadow
                onChange={(event) => {
                  setInviteVerifyEmail(event.target.value);
                }}
                onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={"gray"}
              />
            </div>

            <div className="w-full flex justify-end">
              <ButtonWithRef
                className="h-[35px] w-fit px-10 text-sm desktop:text-base"
                onClick={login}
              >
                Continue
              </ButtonWithRef>
            </div>

            {error && (
              <>
                <div className="font-medium text-red-600 text-[12px]">
                  {error}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
