import { useState } from "react";

import { encode as base64_encode } from "base-64";
import { LoadingScreenSpinner } from "components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

import { RegisterForm } from "./register-form";

export function PartnerRegisterClaim({ claimInfo }) {
  const [registerStep, setRegisterStep] = useState(); // abn / claim / confirm

  const navigate = useNavigate();

  return (
    <>
      {!registerStep && <LoadingScreenSpinner />}

      <div className="content-body">
        <RegisterForm
          claimInfo={claimInfo}
          registerStep={registerStep}
          setRegisterStep={setRegisterStep}
          onRegisterSuccess={() => {
            navigate(
              `${ROUTES.SELF.PARTNER_LOGIN}?c=${encodeURIComponent(
                base64_encode(
                  JSON.stringify({
                    claimed_success: true,
                  })
                )
              )}`
            );
          }}
        />
      </div>
    </>
  );
}
