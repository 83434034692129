import { lookupABN, verifyLookupABN } from "api";
import { sanitizeInputWithSpace } from "services/utils";

const lookup = async ({ abn, checkoutId }) => {
  const ABN = sanitizeInputWithSpace(abn);
  if (ABN.length !== 11 || !/^\d+$/.test(ABN)) {
    return {
      result: undefined,
      error: "Please enter a valid ABN number.",
    };
  }

  let result;

  if (!!checkoutId) {
    result = await verifyLookupABN({ ABN, checkoutId });
  } else {
    result = await lookupABN({ ABN });
  }

  return result;
};

export const ABNService = {
  lookup,
};
