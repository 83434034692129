import React from "react";
import { Modal } from "react-bootstrap";
export function CreateAttestationLetterDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">Create the Attestation Letter</h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Clip+04.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
        <p className="font-medium text-primary-dark">
          Step 3 requires you to generate and sign your attestation letter.
        </p>
        <p>
          The attestation letter is a formal document that provides written
          confirmation or verification that all of the requirements have been
          implemented in your organisation.
        </p>
        <p>
          This letter needs to be signed off by an owner or director of your
          organisation and affirms that you have implemented the necessary
          requirements to receive your certification.
        </p>
        <p>
          Add their details in this section and the letter will be sent to them
          for signing to the email address entered.
        </p>
      </div>
    </>
  );
}
