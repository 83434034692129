import {
  acceptPartnerInvite,
  acceptPartnerNewUserInvite,
  getOrganisationPublic,
  verifyPartnerInvite,
  verifyUserEmail,
} from "api";
import { Confetti } from "components";
import { useLoadingState } from "middleware";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ABNService,
  AuthService,
  sanitizeInputWithSpace,
  validateEmail,
} from "services";
import { ROUTES } from "variables";

import { Alert } from "flowbite-react";
import { HiExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { RegisterFormClaim } from "./register-form-claim";
import { RegisterFormClaimLogin } from "./register-form-claim-login";
import { RegisterFormConfirm } from "./register-form-confirm";
import { RegisterFormSearchABN } from "./register-form-search-abn";

const INITIAL_REGISTER_STATE = {
  abn: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  cPassword: "",
  code: ["", "", "", "", "", ""],
  accept: false,
};

export function RegisterForm({
  claimInfo = {},
  registerStep,
  setRegisterStep = () => {},
  onRegisterSuccess = () => {},
}) {
  const [registerState, setRegisterState] = useState(INITIAL_REGISTER_STATE);
  const [abnSearchRes, setAbnSearchRes] = useState();
  const [abnSearchErr, setAbnSearchErr] = useState();
  const [abnAvailable, setAbnAvailable] = useState(false);
  const [abnRegistered, setAbnRegistered] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const confettiRef = useRef(null);
  const pwdStrengthRef = useRef(null);
  const [alertsTranslateY, setAlertsTranslateY] = useState();
  const [showLoginWarning, setShowLoginWarning] = useState(false);
  const [showInviteAcceptWrongAccount, setShowInviteAcceptWrongAccount] =
    useState(false);
  const [existingLogins, setExistingLogins] = useState();
  const { setLoading } = useLoadingState();
  const navigate = useNavigate();
  const [showAccountAlreadyExists, setShowAccountAlreadyExists] =
    useState(false);

  let mainContentHeight = document.querySelector(".main-content")?.clientHeight;
  let formContainerHeight =
    document.querySelector(".form-container")?.clientHeight;

  useEffect(() => {
    const updateAlertPos = () => {
      if (!!mainContentHeight && !!formContainerHeight) {
        // Account for padding (160px), navbar height (75px) and extra space created in main content
        const translateY =
          (mainContentHeight - formContainerHeight) / 4 - (160 - 75) / 2;

        setAlertsTranslateY(translateY);
      }
    };
    updateAlertPos();

    window.addEventListener("resize", updateAlertPos);

    return () => {
      window.removeEventListener("resize", updateAlertPos);
    };
  }, [mainContentHeight, formContainerHeight]);

  const handleInput = (event) => {
    setRegisterState({
      ...registerState,
      [event.target.name]: event.target.value,
    });
  };

  const validateToClaim = (event) => {
    event?.preventDefault();

    setRegisterStep("claim");
  };

  const verifyAndSearchABN = useCallback(async ({ ABN, checkoutId }) => {
    try {
      const lookupResult = await ABNService.lookup({ abn: ABN, checkoutId });
      if (!lookupResult.result) {
        setAbnSearchRes(undefined);
        setAbnSearchErr(lookupResult.error);
        return undefined;
      }

      const existedOrganisation = await getOrganisationPublic(lookupResult.ABN);
      let result = lookupResult.result;

      if (!existedOrganisation) {
        setAbnAvailable(true);
        setAbnRegistered(false);
      } else if (existedOrganisation.confirmed) {
        setAbnSearchErr(lookupResult.result);
        setAbnRegistered(true);
      } else {
        setAbnAvailable(true);
        setAbnRegistered(false);
      }

      setAbnSearchRes(result);
      return result;
    } catch (error) {
      console.error(`ERROR: LOOKUP ABN: ${JSON.stringify(error)}`);
    }
  }, []);

  const searchABN = async (ABN = registerState?.abn) => {
    try {
      setRegisterState((registerState) => ({
        ...registerState,
        abn: sanitizeInputWithSpace(ABN),
      }));

      const lookupResult = await ABNService.lookup({ abn: ABN });
      if (!lookupResult.result) {
        setAbnSearchRes(undefined);
        setAbnSearchErr(lookupResult.error);
        return undefined;
      }

      const existedOrganisation = await getOrganisationPublic(lookupResult.ABN);
      let result = lookupResult.result;

      if (!existedOrganisation) {
        setAbnAvailable(true);
        setAbnRegistered(false);
      } else if (existedOrganisation.confirmed) {
        setAbnSearchErr(lookupResult.result);
        setAbnRegistered(true);
      } else {
        setAbnAvailable(true);
        setAbnRegistered(false);
      }

      setAbnSearchRes(result);
      return result;
    } catch (error) {
      console.error(`ERROR: LOOKUP ABN: ${JSON.stringify(error)}`);
    }
  };

  const resetSearch = () => {
    setRegisterStep("abn");
    setAbnSearchRes(undefined);
    setAbnRegistered(false);
    setAbnSearchErr(false);
    setRegisterState(INITIAL_REGISTER_STATE);
  };

  const validateSSO = async (ssoFn) => {
    setLoading(true);

    const { accept } = registerState;
    let err;
    if (!accept) {
      err = {
        type: "accept",
        message: "Please accept CyberCert’s Terms of use and Privacy Policy.",
      };

      setLoading(false);
      return err;
    }

    try {
      ssoFn();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    return err;
  };

  const validateToRegister = async () => {
    setLoading(true);
    setInternalLoading(true);

    let err;
    // check email
    const { abn, email, firstName, lastName, password, cPassword, accept } =
      registerState;

    let pwdStrength;
    if (!!password) {
      pwdStrength = pwdStrengthRef?.current?.check(password);
    }

    if (!email || !validateEmail(email)) {
      err = { type: "email", message: "Work email address is invalid." };
    } else if (!firstName && !claimInfo.isPartnerClaim) {
      err = {
        type: "firstName",
        message: "First name is invalid.",
      };
    } else if (!lastName && !claimInfo.isPartnerClaim) {
      err = {
        type: "lastName",
        message: "Last name is invalid.",
      };
    } else if (!password) {
      err = {
        type: "password",
        message: "Password is invalid.",
      };
    } else if (!cPassword || password !== cPassword) {
      err = {
        type: "match",
        message: "Passwords do not match.",
      };
    } else if (!pwdStrength?.accepted) {
      err = {
        type: "password",
        message: "Password needs to be at least 8 characters.",
      };
    } else if (!accept) {
      err = {
        type: "accept",
        message: "Please accept CyberCert’s Terms of use and Privacy Policy.",
      };
    } else {
      err = null;
    }

    if (!err) {
      try {
        await checkPartnerInvite();
        await checkUserInvite();
        await AuthService.signUp(
          { email, firstName, lastName, password, abn },
          () => {
            setRegisterStep("confirm");
          },
          () => {}
        );
      } catch (e) {
        err = {
          type: "register",
          message: e.message,
        };
      }
    }

    setLoading(false);
    setInternalLoading(false);
    return err;
  };

  const confirmCode = async (email, code) => {
    setLoading(true);

    let err;

    try {
      await AuthService.confirmSignup(email, code, onRegisterSuccess);
    } catch (e) {
      err = "Please check if the verification code is correct.";
    }

    setLoading(false);
    return err;
  };

  const resendCode = async (state = registerState) => {
    setLoading(true);

    const { email } = state || {};
    let err;

    if (!email) return "No email.";

    try {
      await AuthService.resendConfirmationCode(email);
    } catch (e) {
      err = "Resend failed.";
    }

    setLoading(false);
    return err;
  };

  const registerGG = async () => {
    if (!!registerState.abn) {
      await checkPartnerInvite();
      let userInvite = {
        isPartnerClaim: true,
        ABN: claimInfo?.invite?.ABN,
        email: claimInfo?.invite?.email,
        firstName: claimInfo?.invite?.firstName,
        lastName: claimInfo?.invite?.lastName,
        invite: claimInfo?.invite?.id,
        inviteUsed: true,
      };
      await AuthService.signupWithGoogle({
        ABN: registerState.abn,
        userInvite,
        claimInfo: JSON.stringify({
          ...claimInfo,
          claim: true,
        }),
      });
    }
  };

  async function checkPartnerInvite() {
    if (!!claimInfo?.claimCode) {
      await acceptPartnerInvite({
        code: claimInfo?.claimCode,
        ABN: registerState?.abn,
      });
    }
  }

  async function checkUserInvite() {
    if (!!claimInfo?.invite) {
      await acceptPartnerNewUserInvite({
        userInvites: [claimInfo?.invite?.id],
      });
    }
  }

  const setup = useCallback(async () => {
    if (claimInfo?.isPartnerClaim === true) {
      let userVerify;
      if (!!claimInfo?.ABN) {
        const result = await verifyAndSearchABN({
          ABN: claimInfo?.ABN,
          checkoutId: claimInfo?.checkoutId,
        });
        if (!result?.ABN) window.location.href = ROUTES.ROOT;
        setRegisterState((registerState) => ({
          ...registerState,
          abn: result?.ABN,
          email: claimInfo.email,
          firstName: claimInfo.firstName,
          lastName: claimInfo.lastName,
        }));
      } else if (!!claimInfo?.invite) {
        userVerify = await verifyUserEmail({
          email: claimInfo?.invite?.email,
        });
        setExistingLogins(userVerify?.users);
        setRegisterState((registerState) => ({
          ...registerState,
          abn: claimInfo?.invite?.ABN,
          email: claimInfo?.invite?.email,
          firstName: claimInfo?.invite?.firstName,
          lastName: claimInfo?.invite?.lastName,
        }));
      } else {
        window.location.href = ROUTES.ROOT;
      }

      if (claimInfo?.inviteError) {
        setShowInviteAcceptWrongAccount(true);
      }

      if (userVerify?.users?.length > 0) {
        setShowLoginWarning(true);
        setRegisterStep("claim_login");
      } else {
        setRegisterStep("claim");
      }
    } else if (claimInfo?.confirm === true && !!claimInfo?.email) {
      setRegisterState((registerState) => ({
        ...registerState,
        email: claimInfo.email,
        code: claimInfo.c?.split("") || INITIAL_REGISTER_STATE.code,
      }));
      if (claimInfo.resend) {
        resendCode({ email: claimInfo.email });
      }
      setRegisterStep("confirm");
    } else if (!!claimInfo?.ABN) {
      if (claimInfo?.error === "ACCOUNT_EXISTED") {
        setShowAccountAlreadyExists(true);
      }

      const result = await verifyAndSearchABN({
        ABN: claimInfo.ABN,
        checkoutId: claimInfo.checkoutId,
      });
      if (!result?.ABN) window.location.href = ROUTES.ROOT;
      setRegisterState((registerState) => ({
        ...registerState,
        abn: result.ABN,
      }));
      setRegisterStep("claim");
      if (claimInfo?.purchased === true) {
        confettiRef.current?.startAnimation();
      }
    } else if (
      !!claimInfo?.ABN &&
      !!claimInfo?.partnerABN &&
      !!claimInfo?.claimCode
    ) {
      const verify = await verifyPartnerInvite({
        code: claimInfo?.claimCode,
      });
      if (verify?.ABN === claimInfo?.partnerABN) {
        const result = await verifyAndSearchABN({
          ABN: claimInfo.ABN,
          checkoutId: claimInfo.checkoutId,
        });
        if (!result?.ABN) window.location.href = ROUTES.ROOT;
        setRegisterState({
          ...registerState,
          abn: result.ABN,
        });
        setRegisterStep("claim");
        if (claimInfo?.purchased === true) {
          confettiRef.current?.startAnimation();
        }
      } else {
        window.location.href = ROUTES.ROOT;
      }
    } else {
      window.location.href = ROUTES.ROOT;
    }
  }, [claimInfo]);

  useEffect(() => {
    if (!!registerState?.password) {
      pwdStrengthRef?.current?.check(registerState?.password);
    }
    // else if (!!registerState?.cPassword) {
    //   pwdStrengthRef?.current?.check(registerState?.cPassword);
    // }
  }, [registerState?.password, registerState?.cPassword]);

  useEffect(() => {
    setup();
  }, []);

  const acceptInviteViaEmail = async () => {
    await checkUserInvite();
    navigate(ROUTES.LOGIN);
  };

  const acceptInviteViaGG = async () => {
    await checkUserInvite();
    await AuthService.signinWithGoogle();
  };

  const acceptInviteViaRecover = async () => {
    await checkUserInvite();
    navigate(ROUTES.RESET);
  };

  return (
    <>
      <div
        className="absolute left-0 right-0 top-0 z-10 flex-col gap-4"
        style={{ transform: `translateY(${alertsTranslateY}px)` }}
      >
        {showLoginWarning && (
          <Alert
            color="warning"
            icon={HiExclamationCircle}
            className="w-full -translate-y-1/2"
            onDismiss={() => {
              setShowLoginWarning(false);
            }}
          >
            <span className="text-sm font-semibold">
              Your account has already been created. Please login instead.
            </span>
          </Alert>
        )}

        {showInviteAcceptWrongAccount && (
          <Alert
            color="warning"
            icon={HiExclamationCircle}
            className="w-full -translate-y-1/2"
            onDismiss={() => {
              setShowInviteAcceptWrongAccount(false);
            }}
          >
            <span className="text-sm font-semibold">
              The logged in account did not match the invitation.
            </span>
          </Alert>
        )}

        {showAccountAlreadyExists && (
          <Alert
            color="warning"
            icon={HiExclamationCircle}
            className="w-full -translate-y-1/2"
            onDismiss={() => {
              setShowAccountAlreadyExists(false);
            }}
          >
            <span className="text-sm font-semibold">
              Account claiming failed. Please try another method.
            </span>
          </Alert>
        )}
      </div>

      <div className="form-container">
        {
          {
            abn: (
              <RegisterFormSearchABN
                registerState={registerState}
                handleInput={handleInput}
                search={searchABN}
                abnRegistered={abnRegistered}
                abnSearchRes={abnSearchRes}
                validateContinue={validateToClaim}
                canContinue={abnAvailable}
                abnSearchErr={abnSearchErr}
                resetSearch={resetSearch}
              />
            ),
            claim: (
              <RegisterFormClaim
                loading={internalLoading}
                registerState={registerState}
                isPartnerClaim={claimInfo?.isPartnerClaim}
                handleInput={handleInput}
                validateContinue={validateToRegister}
                pwdStrengthRef={pwdStrengthRef}
                validateSSO={validateSSO}
                registerGG={registerGG}
                abnSearchRes={abnSearchRes}
                resetSearch={resetSearch}
                title={
                  claimInfo?.isPartnerClaim
                    ? "Create your account"
                    : "Claim your account"
                }
                abnTitle={
                  claimInfo?.isPartnerClaim
                    ? "Is this your organisation?"
                    : "Is this your organisation?"
                }
                invite={claimInfo?.invite}
              />
            ),
            claim_login: (
              <RegisterFormClaimLogin
                registerState={registerState}
                invite={claimInfo?.invite}
                existingLogins={existingLogins}
                acceptInviteViaEmail={acceptInviteViaEmail}
                acceptInviteViaGG={acceptInviteViaGG}
                acceptInviteViaRecover={acceptInviteViaRecover}
              />
            ),
            confirm: (
              <RegisterFormConfirm
                registerState={registerState}
                setRegisterState={setRegisterState}
                confirm={confirmCode}
                resend={resendCode}
              />
            ),
          }[registerStep]
        }
      </div>

      <Confetti ref={confettiRef} />
    </>
  );
}
