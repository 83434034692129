const { API } = require("aws-amplify");

export const submitPartnerAgreement = async ({
  isTypeSupplyChain,
  isTypeMembership,
  isTypeMSP,
  isTypeConsultant,
  isTypeFranchise,
  isTypeReferrer,
  isTypeVendor,
  initialDashboardType,
  ABN,
  entityName,
  entityType,
  preferredName,
  dashboardType,
  ACN,
  ANZSIC,
  telephone,
  industrySector,
  website,
  numEmployee,
  headOfficeCountry,
  headOfficeStreetName,
  headOfficeSuburb,
  headOfficeState,
  headOfficePostcode,
  sameAddresses,
  postalCountry,
  postalStreetName,
  postalSuburb,
  postalState,
  postalPostcode,
  adminFirstName,
  adminLastName,
  adminRole,
  adminEmail,
  adminPhone,
  adminAuthMobile,
  sameRelManager,
  relManagerFirstName,
  relManagerLastName,
  relManagerEmail,
  relManagerPhone,
  sameBusManager,
  busManagerFirstName,
  busManagerLastName,
  busManagerEmail,
  busManagerPhone,
  sameRep,
  repFirstName,
  repLastName,
  repEmail,
  repPhone,
  repRole,
  repAddressStreetName,
  repAddressSuburb,
  repAddressState,
  repAddressPostcode,
  repAddressCountry,
  CAPReferralCode,
  CAPReferralCertBundle,
  CAPReferralLinkToReferrer,
}) => {
  const myInit = {
    body: {
      isTypeSupplyChain,
      isTypeMembership,
      isTypeMSP,
      isTypeConsultant,
      isTypeFranchise,
      isTypeReferrer,
      isTypeVendor,
      initialDashboardType,
      ABN,
      name: entityName,
      type: entityType,
      addedAs: preferredName,
      dashboardType,
      ACN,
      ANZSIC,
      orgPhone: telephone,
      industry: industrySector,
      website,
      numEmployees: numEmployee,
      HOaddressCountry: headOfficeCountry,
      HOaddressStreet: headOfficeStreetName,
      HOaddressSuburb: headOfficeSuburb,
      HOaddressState: headOfficeState,
      HOaddressPostcode: headOfficePostcode,
      sameAddresses,
      POaddressCountry: postalCountry,
      POaddressStreet: postalStreetName,
      POaddressSuburb: postalSuburb,
      POaddressState: postalState,
      POaddressPostcode: postalPostcode,
      firstName: adminFirstName,
      lastName: adminLastName,
      role: adminRole,
      email: adminEmail,
      workPhone: adminPhone,
      phone: adminAuthMobile,
      sameRelManager,
      RMFirstName: relManagerFirstName,
      RMLastName: relManagerLastName,
      RMEmail: relManagerEmail,
      RMPhone: relManagerPhone,
      sameBusManager,
      BDMFirstName: busManagerFirstName,
      BDMLastName: busManagerLastName,
      BDMEmail: busManagerEmail,
      BDMPhone: busManagerPhone,
      sameRep,
      RANaddressFirstName: repFirstName,
      RANaddressLastName: repLastName,
      RANaddressEmail: repEmail,
      RANaddressPhone: repPhone,
      RANaddressRole: repRole,
      RANaddressStreet: repAddressStreetName,
      RANaddressSuburb: repAddressSuburb,
      RANaddressState: repAddressState,
      RANaddressPostcode: repAddressPostcode,
      RANaddressCountry: repAddressCountry,
      CAPReferralCode,
      CAPReferralCertBundle,
      CAPReferralLinkToReferrer,
    },
  };

  let result;
  try {
    result = await API.post("partner", "/agreement/submit", myInit);
  } catch (e) {
    console.log(e);
  } // console.log(result);
  return result;
};
