import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  message: null,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingMsg: (state, action) => {
      state.message = action.payload;
    },
  },
});

const { setLoading, setLoadingMsg } = loadingSlice.actions;

export const setLoadingAction = setLoading;
export const setLoadingMsgAction = setLoadingMsg;

export default loadingSlice.reducer;
