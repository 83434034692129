import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  HiChevronDown,
  HiChevronUp,
} from 'react-icons/hi';
import { handleKeyDownInput } from 'services';

export function Select({
  className,
  disabled,
  error,
  options,
  value,
  setValue,
}) {
  const selectRef = useRef();

  const [isFocused, setIsFocused] = useState(false);
  const [showSelect, setShowSelect] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (value) => {
    setValue(value);
    toggleSelect();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const toggleSelect = (event) => {
    event?.preventDefault();

    if (options?.length > 1) {
      setShowSelect(!showSelect);
    }
  };

  useEffect(() => {
    if (selectRef?.current) {
      showSelect ? setIsFocused(true) : setIsFocused(false);
    }
  }, [showSelect]);

  return (
    <>
      <div
        ref={selectRef}
        className={`relative flex flex-col self-stretch ${className}`}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        <button
          className={`disabled:btn-disabled flex items-center justify-between self-stretch border border-gray-300 bg-gray-50 px-4
                    py-[13px] disabled:cursor-not-allowed
                    ${error ? "border-red-500" : ""}
                    ${showSelect ? "rounded-t-lg" : "rounded-lg"}
                    ${isFocused ? "border-gray-900 ring-1 ring-gray-900" : ""}`}
          disabled={options?.length <= 1 || disabled}
          onClick={toggleSelect}
          onKeyDown={(event) => handleKeyDownInput(event)}
        >
          <div className="flex w-full items-center gap-2">{options[value]}</div>

          {showSelect ? (
            <HiChevronUp className="h-5 w-5" />
          ) : (
            <HiChevronDown className="h-5 w-5" />
          )}
        </button>

        {showSelect && (
          <div
            className="absolute z-10 flex w-full self-stretch rounded-b-lg border border-t-0 border-gray-900 bg-alice-blue ring-1 ring-gray-900"
            style={{ top: selectRef.current.clientHeight }}
          >
            <div className="flex w-full flex-col self-stretch">
              {options.map(
                (item, index) =>
                  index !== value && (
                    <div
                      key={index}
                      className="flex cursor-pointer items-center gap-2 rounded px-3 py-2 hover:bg-blue-200"
                      onClick={() => handleChange(index)}
                    >
                      {item}
                    </div>
                  ),
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
