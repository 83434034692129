import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import the language detector
import enAuTranslation from './en-au/translation.json';
import frFrTranslation from './fr-fr/translation.json';

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    resources: {
      'fr-fr': {
        translation: frFrTranslation,
      },
      'en-au': {
        translation: enAuTranslation,
      }
    },
    lng: 'en-au', // Set the default language
    fallbackLng: 'en-au', // Fallback language if translation key is missing
    interpolation: {
      escapeValue: false, // React already escapes data
    },
    detection: {
      // Configuration for the language detector
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
  });

export default i18n;

