import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import ReactCanvasConfetti from 'react-canvas-confetti';

const DURATION = 2 * 1000;
const DEFAULTS = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
const CANVAS_STYLES = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

export const Confetti = forwardRef((props, ref) => {
  const refAnimationInstance = useRef(null);

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current({
        ...DEFAULTS,
        particleCount: 90,
        origin: {
          x: randomInRange(0.1, 0.3),
          y: Math.random() - 0.2,
        },
      });
      refAnimationInstance.current({
        ...DEFAULTS,
        particleCount: 90,
        origin: {
          x: randomInRange(0.7, 0.9),
          y: Math.random() - 0.2,
        },
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    startAnimation() {
      const intervalId = setInterval(nextTickAnimation, 400);
      setTimeout(() => {
        clearInterval(intervalId);
      }, DURATION);
    },
  }));

  return (
    <ReactCanvasConfetti refConfetti={getInstance} style={CANVAS_STYLES} />
  );
});
