import { useCallback, useEffect, useRef, useState } from "react";

export function HoneypotForm({ children, onSubmit = (event) => {}, ...props }) {
  const formRef = useRef(null);
  const [honeypots, setHoneypots] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check honeypot fields
    const isBotSubmission = Array.from(
      document.querySelectorAll(".honey")
    ).some((field) =>
      field.type === "checkbox" ? field.checked : field.value
    );

    if (isBotSubmission) {
      console.log("Bot submission detected!");
    } else {
      // Handle valid form submission
      onSubmit(event);
    }
  };

  const generateHoneypots = useCallback((element) => {
    if (!element) return [];

    let inputs = [];

    // Recursive function to traverse child elements
    const traverse = (el) => {
      if (el.classList.contains("honey")) return;
      if (
        el.tagName === "INPUT" ||
        el.tagName === "TEXTAREA" ||
        el.tagName === "SELECT" ||
        el.classList.contains("country-input")
      ) {
        inputs.push({
          name: el.name || el.getAttribute("name"),
          type: el.type || el.getAttribute("type"),
        });
      }
      Array.from(el.children).forEach(traverse); // Recursively traverse children
    };

    traverse(element); // Start traversal from the current element
    return inputs;
  }, []);

  useEffect(() => {
    if (formRef.current) {
      setHoneypots(generateHoneypots(formRef.current));
    }
  }, [generateHoneypots]);

  /**
   * Simulate a bot filling out the form
   */
  // function simulateBot() {
  //   console.log("Simulating bot...");
  //   // Select the form
  //   const form = document.querySelector("form"); // Adjust the selector if necessary

  //   // Check if the form exists
  //   if (!form) {
  //     console.error("Form not found!");
  //     return;
  //   }

  //   // Fill out the honeypot fields
  //   const honeypotFields = form.querySelectorAll(".honey");
  //   honeypotFields.forEach((field) => {
  //     field.value = "bot"; // Simulate bot input
  //   });

  //   // Submit the form
  // }

  return (
    <form ref={formRef} onSubmit={handleSubmit} {...props}>
      {children}

      {/* Honeypot fields */}
      {honeypots.map((field, index) => (
        <input
          key={index}
          className="honey"
          id={field.name}
          name={field.name}
          type={field.type}
        />
      ))}
    </form>
  );
}
