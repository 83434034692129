import {
  useEffect,
  useState,
} from 'react';

import {
  HiChevronLeft,
  HiChevronRight,
} from 'react-icons/hi';

export function Carousel({ children, className, onChange }) {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeSlide = (newSlide) => {
    setActiveSlide(Math.max(Math.min(newSlide, children?.length - 1), 0));
  };

  const handleNextSlide = () => {
    handleChangeSlide(activeSlide + 1);
  };

  const handlePrevSlide = () => {
    handleChangeSlide(activeSlide - 1);
  };

  useEffect(() => {
    if (onChange) {
      onChange(activeSlide);
    }
  }, [activeSlide, onChange]);

  return (
    <>
      <div className={`flex flex-col gap-10 overflow-hidden ${className}`}>
        <div
          className={`flex w-full transition-transform duration-500 ease-in-out`}
          style={{ transform: `translateX(${-activeSlide * 100}%)` }}
        >
          {children?.map((child, index) => (
            <div key={index} className="mx-auto w-full shrink-0">
              {child}
            </div>
          ))}
        </div>

        <div className="flex w-[140px] items-center justify-between self-center">
          <button
            className={`
              flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-800 outline-none
            hover:bg-opacity-80 focus:bg-opacity-80 disabled:bg-gray-300
            `}
            onClick={handlePrevSlide}
            disabled={activeSlide === 0}
          >
            <HiChevronLeft className={`h-[18px] w-[18px] text-white`} />
          </button>

          <div className={`flex gap-3`}>
            {children?.map((_, index) => (
              <button
                key={index}
                className={`h-3 w-3 rounded-full bg-gray-300 outline-none disabled:bg-gray-800`}
                onClick={() => handleChangeSlide(index)}
                disabled={index === activeSlide}
                tabIndex={-1}
              />
            ))}
          </div>

          <button
            className={`
              flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-800 outline-none
              hover:bg-opacity-80 focus:bg-opacity-80 disabled:bg-gray-300
            `}
            onClick={handleNextSlide}
            disabled={activeSlide === children?.length - 1}
          >
            <HiChevronRight className="h-[18px] w-[18px] text-white" />
          </button>
        </div>
      </div>
    </>
  );
}
